<template>
  <app-loader
    v-if="
      element.propertiesArray[element.properties.selectedVariationIndex]
        .isLoading
    "
  ></app-loader>
  <div class="video-placeholder d-inline-table">
    <a
      :href="
        element.propertiesArray[element.properties.selectedVariationIndex]
          .videoUrl
      "
      target="_blank"
      style="width: 100%; height: auto"
    >
      <img
        ref="videoWidth"
        style="width: 100%; height: auto"
        :src="
          element.propertiesArray[element.properties.selectedVariationIndex]
            .videoThumbnail
        "
        alt=""
        :width="
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.style?.width === 'auto'
            ? 'auto'
            : element.propertiesArray[element.properties.selectedVariationIndex]
                ?.video_width?.width
        "
        :style="{
          width:
            element.propertiesArray[element.properties.selectedVariationIndex]
              ?.style?.width === 'auto'
              ? 'auto'
              : element.propertiesArray[
                  element.properties.selectedVariationIndex
                ]?.video_width?.width + 'px',
          maxWidth: '100%',
          ...element.propertiesArray[element.properties.selectedVariationIndex]
            .style,
          ...((element.propertiesArray[
            element.properties.selectedVariationIndex
          ]?.borderStyles?.borderTopWidth != '0px' ||
            element.propertiesArray[element.properties.selectedVariationIndex]
              ?.borderStyles?.borderRightWidth != '0px' ||
            element.propertiesArray[element.properties.selectedVariationIndex]
              ?.borderStyles?.borderBottomWidth != '0px' ||
            element.propertiesArray[element.properties.selectedVariationIndex]
              ?.borderStyles?.borderLeftWidth != '0px') && {
            ...element.propertiesArray[
              element.properties.selectedVariationIndex
            ]?.borderStyles,
          }),
        }"
      />
    </a>
  </div>
</template>

<script>
import { throttleFunction } from "@/utils/helper";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";

export default {
  name: "videoViewRender",
  inject: ["draggable_editor_data"],
  components: {
    AppLoader,
  },
  props: {
    element: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    mainContainerWidth: String,
    responsive: Boolean,
  },
  watch: {
    $props: {
      handler() {
        this?.videoWidthModifyHandler();
      },
      deep: true,
      immediate: false,
    },

    responsive() {
      this?.videoWidthModifyHandler();
    },

    mainContainerWidth() {
      this?.videoWidthModifyHandler();
    },
  },
  created() {
    this.videoWidthModifyHandler = throttleFunction(
      this.modify_video_width,
      100
    );

    window.addEventListener(
      "resize",
      throttleFunction(this.widthChangeHandler, 0)
    );
  },
  mounted() {
    this?.videoWidthModifyHandler();
  },
  unmounted() {
    window.removeEventListener("resize", this.widthChangeHandler);
  },
  methods: {
    widthChangeHandler() {
      this.videoWidthModifyHandler();
    },
    modify_video_width() {
      if (this.columnType) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.element.properties.selectedVariationIndex
        ].video_width.width = this.$refs.videoWidth?.clientWidth;
      }
    },
  },
};
</script>
