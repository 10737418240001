<template>
  <!-- <div class="ql-editor" :style="element.properties.style" v-if="element.name === 'widget_text'" v-html="element.properties.content"></div> -->
  <div class="widget-text" :style="{
    ...element.propertiesArray[element.properties.selectedVariationIndex]
      .style,
    ...((element.propertiesArray[element.properties.selectedVariationIndex]
      ?.borderStyles?.borderTopWidth != '0px' ||
      element.propertiesArray[element.properties.selectedVariationIndex]
        ?.borderStyles?.borderRightWidth != '0px' ||
      element.propertiesArray[element.properties.selectedVariationIndex]
        ?.borderStyles?.borderBottomWidth != '0px' ||
      element.propertiesArray[element.properties.selectedVariationIndex]
        ?.borderStyles?.borderLeftWidth != '0px') && {
      ...element.propertiesArray[element.properties.selectedVariationIndex]
        ?.borderStyles,
    }),boxSizing: 'border-box'
  }" v-if="element.name === 'widget_text'" v-html="
  element.propertiesArray[element.properties.selectedVariationIndex].content
"></div>
</template>

<script>
export default {
  name: "textViewRender",
  components: {},
  props: {
    element: Object,
  },
  methods: {},
};
</script>

<style scoped>

</style>
