<template>
  <!--  <div ref="spacerWidth">-->
  <!--    <div style="box-sizing: border-box" :style="{-->
  <!--      ...element.propertiesArray[element.properties.selectedVariationIndex]-->
  <!--        .style,-->
  <!--      width: element.propertiesArray[element.properties.selectedVariationIndex]-->
  <!--        .spacer_width-->
  <!--        ? (parseInt(element.propertiesArray[element.properties.selectedVariationIndex]-->
  <!--          .spacer_width) + 4) + 'px'-->
  <!--        : '100%',-->
  <!--      ...((element.propertiesArray[element.properties.selectedVariationIndex]-->
  <!--        ?.borderStyles?.borderTopWidth != '0px' ||-->
  <!--        element.propertiesArray[element.properties.selectedVariationIndex]-->
  <!--          ?.borderStyles?.borderRightWidth != '0px' ||-->
  <!--        element.propertiesArray[element.properties.selectedVariationIndex]-->
  <!--          ?.borderStyles?.borderBottomWidth != '0px' ||-->
  <!--        element.propertiesArray[element.properties.selectedVariationIndex]-->
  <!--          ?.borderStyles?.borderLeftWidth != '0px') && {-->
  <!--        ...element.propertiesArray[element.properties.selectedVariationIndex]-->
  <!--          ?.borderStyles,-->
  <!--      }),-->
  <!--    }"></div>-->
  <!--  </div>-->
  <table border="0" cellpadding="0" cellspacing="0" align="center" width="100%" style="width: 100%">
    <tbody>
      <tr>
        <td valign="top" border="0" cellpadding="0" cellspacing="0"
          :style="{ background: element.propertiesArray[element.properties.selectedVariationIndex].style.backgroundColor }"
          style="font-size:0px;word-break:break-word;">
          <div :style="{
            height: element.propertiesArray[element.properties.selectedVariationIndex].style.height,
            lineHeight: element.propertiesArray[element.properties.selectedVariationIndex].style.height
          }">
            &nbsp;
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { throttleFunction } from "@/utils/helper";

export default {
  name: "spacerViewRender",
  inject: ["draggable_editor_data"],
  components: {},
  props: {
    element: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    mainContainerWidth: String,
    responsive: Boolean,
  },

  watch: {
    $props: {
      handler() {
        this.spacerWidthModifyHandler();
      },
      deep: true,
      immediate: false,
    },
    mainContainerWidth() {
      this.spacerWidthModifyHandler();
    },

    responsive() {
      this.spacerWidthModifyHandler();
    },
  },
  created() {
    this.spacerWidthModifyHandler = throttleFunction(this.modify_spacer_width, 0);
    window.addEventListener(
      "resize",
      throttleFunction(this.widthChangeHandler, 0)
    );
  },
  mounted() {
    this.spacerWidthModifyHandler();
  },

  unmounted() {
    window.removeEventListener("resize", this.widthChangeHandler);
  },

  methods: {
    widthChangeHandler() {
      this.spacerWidthModifyHandler();
    },
    modify_spacer_width() {
      if (
        this.columnType &&
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.element.properties.selectedVariationIndex]
          .spacer_width
      ) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.element.properties.selectedVariationIndex
        ].spacer_width.width = this.$refs.spacerWidth?.clientWidth + "px";
      }
    },
  },
};
</script>
