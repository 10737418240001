<template>
  <div class="widget-htmlEditor" v-if="this.element.name === 'widget_product_editor'">
    <div v-if="this.element.name === 'widget_product_editor' &&
      this.element.propertiesArray[
        this.element.properties.selectedVariationIndex
      ].tableProperties
      " class="product-table">
      <table :style="tableStyle" v-if="this.element.name === 'widget_product_editor' &&
        !this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].tableProperties.isDynamic
        ">
        <tbody>
          <template v-for="(column, columnIndex) in calculateRows" :key="columnIndex">
            <tr v-if="column <= calculateRows">
              <td style="padding: 0 0 10px;">
                <!-- Style One -->
                <div style="display: table; width: 100%; height: 100%">
                  <template v-if="this.element.propertiesArray[
                    this.element.properties.selectedVariationIndex
                  ].layoutOrientation.isHorizontal
                    ">
                    <div v-for="(row, cellIndex) in this.element.propertiesArray[
                          this.element.properties.selectedVariationIndex
                        ].productsPerRow" :key="cellIndex" class="product-cell-stack-one" :style="{
      width: `${100 /
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].productsPerRow
        }%`,
    }">
                      <div v-if="getProductByIndex(columnIndex, cellIndex)?.id" :style="rowBorderStyleDiv"
                        style="height: 100%">
                        <table style="table-layout:fixed;height:100%;width:100%;">
                          <tbody>
                            <tr>
                              <td>
                                <div ref="picWidth" style="margin:0 0 16px;display:inline-block;">
                                  <img :width="parseInt(
                                    element.propertiesArray[
                                      element.properties.selectedVariationIndex
                                    ].img_width.width,
                                    10
                                  )
                                    " :style="tableImageStyle" style="display:block;max-width:100%;width:auto;"
                                    loading="lazy" :src="getProductByIndex(columnIndex, cellIndex)
                                      ?.image ||
                                      `https://clubeez-v2-staging.s3-ap-southeast-1.amazonaws.com/5f9a2bbe-87fe-4ec2-ac80-af2b43ebfe0d_638524859468207872.png`
                                      " alt="" />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="padding: 0 0 4px;">
                                <div v-if="isElementVisible('name')">
                                  <a :style="productNameStyle" href=""
                                    style="display: block; word-break: break-word; line-height:1.2;">
                                    {{
                                      getProductByIndex(columnIndex, cellIndex)?.name
                                    }}
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div>
                                  <h4 v-if="isElementVisible('price') ||
                                      isElementVisible('salePrice')
                                      " style="margin: 0 0 16px; line-height:1.2;">
                                    <span style="word-break:break-word;" :style="productPriceStyle(getProductByIndex(columnIndex, cellIndex)
                                      ?.salePrice, getProductByIndex(columnIndex, cellIndex)
                                      ?.basePrice)" v-if="isElementVisible('price')">
                                      ${{
                                        getProductByIndex(columnIndex, cellIndex)
                                          ?.basePrice
                                      }}
                                    </span>
                                    <span :style="productSalePriceStyle" v-if="isElementVisible('salePrice')"
                                      style="word-break:break-word;">
                                      {{
                                        (getProductByIndex(columnIndex, cellIndex)
                                          ?.salePrice > 0 && getProductByIndex(columnIndex, cellIndex)
                                            ?.salePrice != getProductByIndex(columnIndex, cellIndex)
                                            ?.basePrice) ? ' $' + getProductByIndex(columnIndex, cellIndex)
                                              ?.salePrice : ""
                                      }}
                                    </span>
                                  </h4>
                                  <p style="word-break: break-word;" :style="productDescriptionStyle"
                                    v-if="isElementVisible('description')" v-html="getProductByIndex(columnIndex, cellIndex)
                                      ?.custom_description
                                      " />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="height:100%;"></td>
                            </tr>
                            <tr>
                              <td style="padding: 16px 0 0;">
                                <div class="button-wrap" ref="buttonWidth" style="display: inline-flex;">
                                  <table border="0" cellpadding="0" cellspacing="0" style="border-collapse:separate;line-height:100%;">
                                    <tbody>
                                      <tr>
                                        <td :style="widgetButtonStyle">
                                          <a style="color: #ffffff;word-break: break-word;">
                                            <span :style="widgetButtonSpanStyle">
                                              {{
                                                this.element.propertiesArray[
                                                  this.element.properties.selectedVariationIndex
                                                ].buttonProperties.btn_text
                                              }}
                                            </span>
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </template>

                  <!-- Style Two -->
                  <div class="product-cell-stack-two-wrap" v-if="this.element.propertiesArray[
                    this.element.properties.selectedVariationIndex
                  ].layoutOrientation.isVerticalRight
                    ">
                    <table cellpadding="0" cellspacing="0" height="100%" role="presentation" width="100%" v-for="(row, cellIndex) in this.element.propertiesArray[
                          this.element.properties.selectedVariationIndex
                        ].productsPerRow" :key="cellIndex" class="product-cell-stack-two">
                      <tbody>
                        <tr v-if="column <= calculateRows" :style="trBorderStyleDiv">
                          <td :style="tdPaddingStyleDiv" width="50%">
                            <div ref="picWidth_2" style="display:inline-block;">
                              <img :width="parseInt(
                                element.propertiesArray[
                                  element.properties.selectedVariationIndex
                                ].img_width.width,
                                10
                              )
                                " :style="tableImageStyle" style="display:block;max-width:100%;width:auto;" :src="getProductByIndex(columnIndex, cellIndex)
    ?.image ||
    'https://clubeez-v2-staging.s3-ap-southeast-1.amazonaws.com/5f9a2bbe-87fe-4ec2-ac80-af2b43ebfe0d_638524859468207872.png'
    " alt="" />
                            </div>
                          </td>
                          <td :style="tdPaddingStyleDiv" width="50%">
                            <table cellpadding="0" cellspacing="0" role="presentation" width="100%">
                              <tbody>
                                <tr>
                                  <td style="padding: 0 0 4px;">
                                    <div v-if="isElementVisible('name')">
                                      <a :style="productNameStyle" href=""
                                        style="display: block; word-break: break-word; line-height:1.2;">
                                        {{
                                          getProductByIndex(columnIndex, cellIndex)
                                            ?.name
                                        }}
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <h4 v-if="isElementVisible('price') ||
                                isElementVisible('salePrice')
                                " style="margin: 0 0 16px;line-height:1.2;">
                              <span style="word-break:break-word;" v-if="isElementVisible('price')" :style="productPriceStyle(getProductByIndex(columnIndex, cellIndex)
                                ?.salePrice, getProductByIndex(columnIndex, cellIndex)
                                ?.basePrice)">
                                ${{
                                  getProductByIndex(columnIndex, cellIndex)
                                    ?.basePrice
                                }}
                              </span>
                              <span :style="productSalePriceStyle" v-if="isElementVisible('salePrice')"
                                style="word-break:break-word;">
                                {{
                                  (getProductByIndex(columnIndex, cellIndex)
                                    ?.salePrice > 0 && getProductByIndex(columnIndex, cellIndex)
                                      ?.salePrice != getProductByIndex(columnIndex, cellIndex)
                                      ?.basePrice) ? ' $' + getProductByIndex(columnIndex, cellIndex)
                                        ?.salePrice : ""
                                }}
                              </span>
                            </h4>
                            <p style="word-break: break-word;" :style="productDescriptionStyle"
                              v-if="isElementVisible('description')" v-html="getProductByIndex(columnIndex, cellIndex)
                                ?.custom_description
                                " />
                            <div class="button-wrap" ref="buttonWidth" style="display: inline-flex;">
                              <table border="0" cellpadding="0" cellspacing="0" style="border-collapse:separate;line-height:100%;">
                                <tbody>
                                  <tr>
                                    <td :style="widgetButtonStyle">
                                      <a style="color: #ffffff;word-break: break-word;">
                                        <span :style="widgetButtonSpanStyle">
                                          {{
                                            this.element.propertiesArray[
                                              this.element.properties
                                                .selectedVariationIndex
                                            ].buttonProperties.btn_text
                                          }}
                                        </span>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- Style Three -->
                  <div class="product-cell-stack-two-wrap" v-if="this.element.propertiesArray[
                    this.element.properties.selectedVariationIndex
                  ].layoutOrientation.isVerticalLeft
                    ">
                    <table cellpadding="0" cellspacing="0" height="100%" role="presentation" width="100%" v-for="(row, cellIndex) in this.element.propertiesArray[
                      this.element.properties.selectedVariationIndex
                    ].productsPerRow" :key="cellIndex" class="product-cell-stack-two">
                      <tbody>
                        <tr v-if="column <= calculateRows" :style="trBorderStyleDiv">
                          <td :style="tdPaddingStyleDiv" width="50%">
                            <table  cellpadding="0" cellspacing="0" role="presentation" width="100%">
                              <tr>
                                <td style="padding: 0 0 4px;">
                                  <div v-if="isElementVisible('name')">
                                    <a :style="productNameStyle" href=""
                                      style="display: block; word-break: break-word; line-height:1.2;">
                                      {{
                                        getProductByIndex(columnIndex, cellIndex)
                                          ?.name
                                      }}
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <h4 v-if="isElementVisible('price') ||
                                isElementVisible('salePrice')
                                " style="line-height:1.2;margin: 0 0 16px;">
                              <span style="word-break:break-word;" v-if="isElementVisible('price')" :style="productPriceStyle(getProductByIndex(columnIndex, cellIndex)
                                ?.salePrice, getProductByIndex(columnIndex, cellIndex)
                                ?.basePrice)">
                                ${{
                                  getProductByIndex(columnIndex, cellIndex)
                                    ?.basePrice
                                }}
                              </span>
                              <span :style="productSalePriceStyle" v-if="isElementVisible('salePrice')"
                                style="word-break:break-word;">
                                {{
                                  (getProductByIndex(columnIndex, cellIndex)
                                    ?.salePrice > 0 && getProductByIndex(columnIndex, cellIndex)
                                      ?.salePrice != getProductByIndex(columnIndex, cellIndex)
                                      ?.basePrice) ? ' $' + getProductByIndex(columnIndex, cellIndex)
                                        ?.salePrice : ""
                                }}
                              </span>
                            </h4>
                            <p style="word-break: break-word;" :style="productDescriptionStyle"
                              v-if="isElementVisible('description')" v-html="getProductByIndex(columnIndex, cellIndex)
                                ?.custom_description
                                " />
                            <div class="button-wrap" ref="buttonWidth" style="display: inline-flex;">
                              <table border="0" cellpadding="0" cellspacing="0" style="border-collapse:separate;line-height:100%;">
                                <tbody>
                                  <tr>
                                    <td :style="widgetButtonStyle">
                                      <a style="color: #ffffff;word-break: break-word;">
                                        <span :style="widgetButtonSpanStyle">
                                          {{
                                            this.element.propertiesArray[
                                              this.element.properties
                                                .selectedVariationIndex
                                            ].buttonProperties.btn_text
                                          }}
                                        </span>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                          <td :style="tdPaddingStyleDiv" width="50%">
                            <div ref="picWidth_3" style="display:inline-block;">
                              <img :width="parseInt(
                                element.propertiesArray[
                                  element.properties.selectedVariationIndex
                                ].img_width.width,
                                10
                              )
                                " :style="tableImageStyle" style="display:block;max-width:100%;width:auto;" :src="getProductByIndex(columnIndex, cellIndex)
    ?.image ||
    'https://clubeez-v2-staging.s3-ap-southeast-1.amazonaws.com/5f9a2bbe-87fe-4ec2-ac80-af2b43ebfe0d_638524859468207872.png'
    " alt="" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { throttleFunction } from "@/utils/helper";
export default {
  name: "productViewRender",

  inject: [
    "draggable_editor_data",
    "draggable_component_settings",
    "draggable_editor_settings",
    "draggable_editor_settings_child",
    "draggable_block_settings",
    "wrapperContainerStyle",
  ],

  components: {},
  props: {
    element: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    mainContainerWidth: String,
    responsive: Boolean,
  },
  data() {
    return {
      fromResponsive: false,
    };
  },
  computed: {
    calculateRows() {
      let obj =
        this.element.propertiesArray[
        this.element.properties.selectedVariationIndex
        ];

      let value = obj.numberOfRows;

      if (obj.productAddProcessType === "Static") {
        if (
          obj.layoutOrientation.isVerticalRight ||
          obj.layoutOrientation.isVerticalLeft
        ) {
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].productsPerRow = 1;
        }

        let productsPerRow = obj.productsPerRow;
        let productCount = obj.productsList.length;

        value = Math.ceil(productCount / productsPerRow);
      } else {
        // value = obj.numberOfRows;
        value = 1;
      }
      return value || 1;
    },
    tableStyle() {
      const selectedIndex = this.element.properties.selectedVariationIndex;
      const selectedProperties = this.element.propertiesArray[selectedIndex];
      const { style, borderStyles } = selectedProperties;
      const hasBorder =
        borderStyles &&
        (borderStyles.borderTopWidth !== "0px" ||
          borderStyles.borderRightWidth !== "0px" ||
          borderStyles.borderBottomWidth !== "0px" ||
          borderStyles.borderLeftWidth !== "0px");

      return {
        ...style,
        ...(hasBorder && borderStyles),
      };
    },
    tableTdStyle() {
      const selectedIndex = this.element.properties.selectedVariationIndex;
      const selectedProperties = this.element.propertiesArray[selectedIndex];
      const tableProperties = selectedProperties.tableProperties;
      const fullTableStyle = tableProperties.fullTableStyle;
      const tableBorderStyle = tableProperties.tableBorderSelectedStyle;
      const cellPaddingStyle = tableProperties.table_cell_padding_style;

      return {
        backgroundColor: fullTableStyle.isVisible
          ? fullTableStyle.backgroundColor
          : "#FFFFFF",
        border: `1px ${tableBorderStyle.borderStyle} ${tableBorderStyle.borderColor}`,
        paddingTop: cellPaddingStyle.paddingTop,
        paddingRight: cellPaddingStyle.paddingRight,
        paddingBottom: cellPaddingStyle.paddingBottom,
        paddingLeft: cellPaddingStyle.paddingLeft,
      };
    },
    rowBorderStyleDiv() {
      const selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      const selectedVariation =
        this.element.propertiesArray[selectedVariationIndex];
      return {
        ...selectedVariation.item_border_styles,
        ...selectedVariation.item_padding_style,
      };
    },
    trBorderStyleDiv() {
      const selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      const selectedVariation =
        this.element.propertiesArray[selectedVariationIndex];
      return {
        ...selectedVariation.item_border_styles,
      };
    },
    tdPaddingStyleDiv() {
      const selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      const selectedVariation =
        this.element.propertiesArray[selectedVariationIndex];
      return {
        ...selectedVariation.item_padding_style,
      };
    },
    tableImageStyle() {
      return {
        maxHeight:
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].maxImageHeight + "px",
      };
    },
    buttonWrapStyle() {
      return {
        ...this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].buttonContainerStyle,
      };
    },
    widgetButtonSpanStyle() {
      const selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      const selectedVariation =
        this.element.propertiesArray[selectedVariationIndex];

      const fontStyle = selectedVariation.button_name_font_style?.isItalic
        ? "italic"
        : "normal";
      const fontWeight = selectedVariation.button_name_font_style?.isBold
        ? "bold"
        : "normal";

      let textDecoration = [];
      if (selectedVariation.button_name_font_style?.isUnderline) {
        textDecoration.push("underline");
      }
      if (selectedVariation.button_name_font_style?.isStrikethrough) {
        textDecoration.push("line-through");
      }

      return {
        fontSize: selectedVariation.button_font_size + "px",
        fontFamily: selectedVariation.button_name_font_family.value,
        fontStyle: fontStyle,
        fontWeight: fontWeight,
        textDecoration: textDecoration.join(" "),
      };
    },
    productNameStyle() {
      const selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      const selectedVariation =
        this.element.propertiesArray[selectedVariationIndex];

      const fontStyle = selectedVariation.text_name_font_style?.isItalic
        ? "italic"
        : "normal";
      const fontWeight = selectedVariation.text_name_font_style?.isBold
        ? "bold"
        : "normal";

      let textDecoration = [];
      if (selectedVariation.text_name_font_style?.isUnderline) {
        textDecoration.push("underline");
      }
      if (selectedVariation.text_name_font_style?.isStrikethrough) {
        textDecoration.push("line-through");
      }

      return {
        fontSize: selectedVariation.text_name_font_size + "px",
        fontFamily: selectedVariation.text_name_font_family.value,
        color: selectedVariation.text_name_color,
        fontStyle: fontStyle,
        fontWeight: fontWeight,
        textDecoration: textDecoration.join(" "),
      };
    },

    productDescriptionStyle() {
      const selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      const selectedVariation =
        this.element.propertiesArray[selectedVariationIndex];

      const fontStyle = selectedVariation.text_description_font_style?.isItalic
        ? "italic"
        : "normal";
      const fontWeight = selectedVariation.text_description_font_style?.isBold
        ? "bold"
        : "normal";

      let textDecoration = [];
      if (selectedVariation.text_description_font_style?.isUnderline) {
        textDecoration.push("underline");
      }
      if (selectedVariation.text_description_font_style?.isStrikethrough) {
        textDecoration.push("line-through");
      }

      return {
        fontSize: selectedVariation.text_description_font_size + "px",
        fontFamily: selectedVariation.text_description_font_family.value,
        color: selectedVariation.text_description_color,
        fontStyle: fontStyle,
        fontWeight: fontWeight,
        textDecoration: textDecoration.join(" "),
        // textAlign: textAlign,
      };
    },
    productSalePriceStyle() {
      const selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      const selectedVariation =
        this.element.propertiesArray[selectedVariationIndex];

      const fontStyle = selectedVariation.text_sale_price_font_style?.isItalic
        ? "italic"
        : "normal";
      const fontWeight = selectedVariation.text_sale_price_font_style?.isBold
        ? "bold"
        : "normal";

      let textDecoration = [];
      if (selectedVariation.text_sale_price_font_style?.isUnderline) {
        textDecoration.push("underline");
      }
      if (selectedVariation.text_sale_price_font_style?.isStrikethrough) {
        textDecoration.push("line-through");
      }

      return {
        fontSize: selectedVariation.text_sale_price_font_size + "px",
        fontFamily: selectedVariation.text_sale_price_font_family.value,
        color: selectedVariation.text_sale_price_color,
        fontStyle: fontStyle,
        fontWeight: fontWeight,
        textDecoration: textDecoration.join(" "),
        // textAlign: textAlign,
      };
    },
    divProductNameStyle() {
      const selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      const selectedVariation =
        this.element.propertiesArray[selectedVariationIndex];

      let textAlign;
      if (selectedVariation.text_name_alignment.isLeft) {
        textAlign = "left";
      } else if (selectedVariation.text_name_alignment.isCenter) {
        textAlign = "center";
      } else if (selectedVariation.text_name_alignment.isRight) {
        textAlign = "right";
      }
      return { textAlign: textAlign };
    },
    widgetButtonStyle() {
      const selectedIndex = this.element.properties.selectedVariationIndex;
      const selectedProperties = this.element.propertiesArray[selectedIndex];
      const buttonProperties = selectedProperties.buttonProperties;
      const buttonStyle = buttonProperties.style || {};
      const buttonBorderStyles = buttonProperties.isBorderApply
        ? selectedProperties.buttonBorderStyles
        : {};
      // const buttonWidth = selectedProperties.button_width?.width;

      const hasBorder = [
        "borderTopWidth",
        "borderRightWidth",
        "borderBottomWidth",
        "borderLeftWidth",
      ].some((side) => buttonBorderStyles[side] !== "0px");

      return {
        ...buttonStyle,
        ...(hasBorder && buttonBorderStyles),
        ...selectedProperties.buttonPaddingstyle,
        ...selectedProperties.buttonCornerStyles,
      };
    },
  },
  watch: {
    $props: {
      handler() {
        this?.imageWidthModifyHandler();
      },
      deep: true,
      immediate: false,
    },
    responsive() {
      this.fromResponsive = true;
      setTimeout(() => {
        this.fromResponsive = false;
      }, 500);
    },
    mainContainerWidth() {
      this?.imageWidthModifyHandler();
    },
  },
  mounted() {
    this.imageWidthModifyHandler();
  },
  created() {
    window.addEventListener(
      "resize",
      throttleFunction(this.imageWidthModifyHandler, 0)
    );
  },
  methods: {
    isElementVisible(type) {
      return this.element.propertiesArray[
        this.element.properties.selectedVariationIndex
      ].itemsVisibility[type].isVisible;
    },
    imageWidthModifyHandler() {
      setTimeout(() => {
        if (this.columnType && !this.fromResponsive) {
          let inputValue = parseInt(
            this.draggable_editor_data[this.index][this.columnType][
              this.childIndex
            ].propertiesArray[this.element.properties.selectedVariationIndex]
              .img_width.width
          );

          let highestWidth;

          if (this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].layoutOrientation.isHorizontal) {

            highestWidth = parseInt(this.$refs.picWidth[0]?.offsetWidth);
            console.log("Horizontal layout selected. Highest width:", highestWidth);

          } else if (this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].layoutOrientation.isVerticalRight) {

            highestWidth = parseInt(this.$refs.picWidth_2[0]?.offsetWidth);
            console.log("Vertical Right layout selected. Highest width:", highestWidth);

          } else if (this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].layoutOrientation.isVerticalLeft) {

            highestWidth = parseInt(this.$refs.picWidth_3[0]?.offsetWidth);
            console.log("Vertical Left layout selected. Highest width:", highestWidth);
          }
          console.log("inputValue:highestWidth", inputValue, highestWidth);
          if (inputValue < highestWidth) {
            inputValue = highestWidth + "px";
          } else if (inputValue > highestWidth) {
            inputValue = highestWidth + "px";
          } else {
            inputValue = inputValue + "px";
          }

          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[
            this.element.properties.selectedVariationIndex
          ].img_width.width = inputValue;
        }
      }, 100);
    },
    productPriceStyle(salePrice, basePrice) {
      const selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      const selectedVariation =
        this.element.propertiesArray[selectedVariationIndex];

      const fontStyle = selectedVariation.text_price_font_style?.isItalic
        ? "italic"
        : "normal";
      const fontWeight = selectedVariation.text_price_font_style?.isBold
        ? "bold"
        : "normal";

      let textDecoration = [];
      if (selectedVariation.text_price_font_style?.isUnderline) {
        textDecoration.push("underline");
      }
      if (selectedVariation.text_price_font_style?.isStrikethrough) {
        textDecoration.push("line-through");
      }

      if (this.isElementVisible("salePrice") && salePrice > 0 && salePrice != basePrice) {
        textDecoration.push("line-through");
      }

      return {
        fontSize: selectedVariation.text_price_font_size + "px",
        fontFamily: selectedVariation.text_price_font_family.value,
        color: selectedVariation.text_price_color,
        fontStyle: fontStyle,
        fontWeight: fontWeight,
        textDecoration: textDecoration.join(" "),
        // textAlign: textAlign,
      };
    },
    getProductByIndex(rowIndex, productsPerRowIndex) {
      let productsPerRow =
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].productsPerRow;
      let obj =
        this.element.propertiesArray[
        this.element.properties.selectedVariationIndex
        ];

      let index = rowIndex * productsPerRow + productsPerRowIndex;
      let data = null;

      if (
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].productsList?.length > 0 &&
        obj.productAddProcessType == "Static"
      ) {
        data =
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].productsList?.[index];
      } else {
        if ([0, 1, 2, 3, 4].includes(index)) {
          data =
            this.element.propertiesArray[
              this.element.properties.selectedVariationIndex
            ].defaultProduct;
        } else {
          data = {};
        }
      }
      return data;
    },
  },
};
</script>
<style scoped></style>
