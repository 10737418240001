<template>
  <div style="" class="dragable-img-view">
    <Draggable
      @change="onDragDataChange"
      :list="tempDragDataArray"
      @start="draggable_editor_child_drag_start"
      @end="draggable_editor_child_drag_end"
      itemKey="'id'"
      :component-data="{
        tag: 'div',
        name: 'draggable_editor_data_child-',
        type: 'transition',
        class: 'list-group---',
      }"
      handle=".drag-handle"
      v-bind="draggable_editor_settings_child"
    >
      <template #item="{ element }">
        <div clas="d-none">{{ element.id }}</div>
      </template>
    </Draggable>
    <table width="100%">
      <tbody>
        <tr>
          <td
            :align="
              this.element.propertiesArray[
                this.element.properties.selectedVariationIndex
              ].container_style?.textAlign
            "
          >
            <table
              border="0"
              cellpadding="0"
              cellspacing="0"
              style="
                border-collapse: collapse;
                border-spacing: 0px;
                margin: 0 !important;
              "
            >
              <tbody>
                <tr>
                  <td
                    ref="picWidth"
                    :style="{
                      width:
                        element.propertiesArray[
                          element.properties.selectedVariationIndex
                        ]?.img_width?.width,
                      ...((element.propertiesArray[
                        element.properties.selectedVariationIndex
                      ]?.borderStyles?.borderTopWidth != '0px' ||
                        element.propertiesArray[
                          element.properties.selectedVariationIndex
                        ]?.borderStyles?.borderRightWidth != '0px' ||
                        element.propertiesArray[
                          element.properties.selectedVariationIndex
                        ]?.borderStyles?.borderBottomWidth != '0px' ||
                        element.propertiesArray[
                          element.properties.selectedVariationIndex
                        ]?.borderStyles?.borderLeftWidth != '0px') && {
                        ...element.propertiesArray[
                          element.properties.selectedVariationIndex
                        ]?.borderStyles,
                      }),
                    }"
                  >
                    <a
                      v-if="element.name === 'widget_image'"
                      :style="{}"
                      :href="
                        element.propertiesArray[
                          element.properties.selectedVariationIndex
                        ].imgUrl.includes('https://')
                          ? element.propertiesArray[
                              element.properties.selectedVariationIndex
                            ].imgUrl
                          : element.propertiesArray[
                              element.properties.selectedVariationIndex
                            ].imgUrl == ''
                          ? ''
                          : 'https://' +
                            element.propertiesArray[
                              element.properties.selectedVariationIndex
                            ].imgUrl
                      "
                      :target="_blank"
                      style="
                        display: block;
                        pointer-events: none;
                        cursor: default;
                        vertical-align: top;
                        max-width: 100%;
                      "
                    >
                      <img
                        :src="
                          element.propertiesArray[
                            element.properties.selectedVariationIndex
                          ].src ||
                          element.propertiesArray[
                            element.properties.selectedVariationIndex
                          ].defaultSrc
                        "
                        :alt="
                          element.propertiesArray[
                            element.propertiesselectedVariationIndex
                          ]?.altText
                        "
                        loading="lazy"
                        :width="
                          parseInt(
                            element.propertiesArray[
                              element.properties.selectedVariationIndex
                            ].img_width.width,
                            10
                          )
                        "
                        :style="{
                          ...element.propertiesArray[
                            element.properties.selectedVariationIndex
                          ].style,
                          display: 'table',
                        }"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { throttleFunction } from "@/utils/helper";
import Draggable from "vuedraggable";
import settingsRoute from "@/router/settings";

export default {
  name: "imageViewRender",
  inject: [
    "draggable_editor_data",
    "draggable_component_settings",
    "draggable_editor_settings",
    "draggable_editor_settings_child",
    "draggable_block_settings",
    "wrapperContainerStyle",
  ],
  components: { Draggable },
  props: {
    element: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    mainContainerWidth: String,
    responsive: Boolean,
  },

  data() {
    return {
      tempDragDataArray: [],
      fromResponsive: false,
    };
  },

  watch: {
    $props: {
      handler() {
        this?.imageWidthModifyHandler();
      },
      deep: true,
      immediate: false,
    },
    responsive() {
      this.fromResponsive = true;
      setTimeout(() => {
        this.fromResponsive = false;
      }, 500);
    },
    mainContainerWidth() {
      this?.imageWidthModifyHandler();
    },
  },
  mounted() {
    this.imageWidthModifyHandler();
  },
  created() {
    window.addEventListener(
      "resize",
      throttleFunction(this.imageWidthModifyHandler, 0)
    );
  },
  methods: {
    imageWidthModifyHandler() {
      // this.$store.dispatch("setPictureTdRef", this.$refs.picWidth);
      setTimeout(() => {
        if (this.columnType && !this.fromResponsive) {
          let inputValue = parseInt(
            this.draggable_editor_data[this.index][this.columnType][
              this.childIndex
            ].propertiesArray[this.element.properties.selectedVariationIndex]
              .img_width.width
          );

          let highestWidth = parseInt(this.$refs.picWidth.offsetWidth);
          if (inputValue > highestWidth) {
            inputValue = highestWidth + "px";
          } else {
            inputValue = inputValue + "px";
          }

          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[
            this.element.properties.selectedVariationIndex
          ].img_width.width = inputValue;
        }
      }, 100);
    },
    draggable_editor_child_drag_start() {
      this.draggable_editor_settings.group.put = false;
    },
    draggable_editor_child_drag_end() {
      this.draggable_editor_settings.group.put = true;
    },
    cloneDog(data) {
      const _data = JSON.parse(JSON.stringify(data));
      _data.id = Math.floor(Math.random() * 100000);
      return _data;
    },
    onDragDataChange() {
      const URL = this.tempDragDataArray[0].propertiesArray[0].src;
      if (URL) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[this.element.properties.selectedVariationIndex].src =
          URL;
      }
      this.tempDragDataArray = [];
    },
  },
};
</script>

<style scoped></style>
