<template src="./EmailTemplateMobileAndDesktopPreview.html"></template>
<style lang="scss">
@import "./EmailTemplateMobileAndDesktopPreview.scss";
</style>

<script>
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { Button } from "@progress/kendo-vue-buttons";
import buttonViewRender from "@/components/emailTemplateEditor/widgets/button/buttonViewRender.vue";
import textViewRender from "@/components/emailTemplateEditor/widgets/text/textViewRender.vue";
import imageViewRender from "@/components/emailTemplateEditor/widgets/image/imageViewRender.vue";
import socialViewRender from "@/components/emailTemplateEditor/widgets/social/socialViewRender";
import lineViewRender from "@/components/emailTemplateEditor/widgets/line/lineViewRender";
import videoViewRender from "@/components/emailTemplateEditor/widgets/video/videoViewRender";
import headerRender from "@/components/emailTemplateEditor/widgets/header/headerRender";
import htmlEditorViewRender from "@/components/emailTemplateEditor/widgets/htmlEditor/htmlEditorViewRender.vue";
import tableViewRender from "@/components/emailTemplateEditor/widgets/table/tableViewRender.vue";
import footerRender from "@/components/emailTemplateEditor/widgets/footer/footerRender.vue";
import spacerViewRender from "@/components/emailTemplateEditor/widgets/spacer/spacerViewRender.vue";
import productViewRender from '@/components/emailTemplateEditor/widgets/product/productViewRender.vue';
import navigationViewRender from '@/components/emailTemplateEditor/widgets/navigation/navigationViewRender.vue';

export default {
  name: "emailTemplatePreview",
  components: {
    Button,
    buttonViewRender,
    textViewRender,
    imageViewRender,
    socialViewRender,
    lineViewRender,
    videoViewRender,
    headerRender,
    htmlEditorViewRender,
    tableViewRender,
    footerRender,
    spacerViewRender,
    productViewRender,
    navigationViewRender,
  },
  props: {
    draggable_editor_data: Array,
    global_style: Object,
    link_style: Object,
    wrapperContainerStyle: Object,
    templateData: Object,
    previewType: String,
    catchTemplateHtml: Function,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    if (typeof this.catchTemplateHtml === "function") {
      setTimeout(() => {
        const style = this.createStyle();
        const head = this.$refs.headrenderer;
        head.appendChild(style);
        const htmlTemplate = this.$refs.previewRender.innerHTML;
        this?.catchTemplateHtml?.(htmlTemplate);
      }, 1000);
    }
  },
  methods: {
    createStyle() {
      let css = "";
      css +=
        "html,\n" +
        "   body {\n" +
        "     margin: 0 auto !important;\n" +
        "     padding: 0 !important;\n" +
        "     height: 100% !important;\n" +
        "     width: 100% !important;\n" +
        "     box-sizing: border-box\n" +
        "   }" +
        "* {\n" +
        "   -ms-text-size-adjust: 100%;\n" +
        "   -webkit-text-size-adjust: 100%;\n" +
        " }\n" +
        "table {\n" +
        "   border-spacing: 0 !important;\n" +
        "   border-collapse: collapse !important;\n" +
        "   table-layout: fixed !important;\n" +
        "   margin: 0 auto !important;\n" +
        "}\n" +
        "img {\n" +
        "    -ms-interpolation-mode: bicubic;\n" +
        "}\n" +
        "a {\n" +
        "   text-decoration: none;\n" +
        " }\n" +
        "*[x-apple-data-detectors],\n" +
        " .unstyle-auto-detected-links *,\n" +
        " .aBn {\n" +
        "   border-bottom: 0 !important;\n" +
        "   cursor: default !important;\n" +
        "   color: inherit !important;\n" +
        "   text-decoration: none !important;\n" +
        "   font-size: inherit !important;\n" +
        "   font-family: inherit !important;\n" +
        "   font-weight: inherit !important;\n" +
        "   line-height: inherit !important;\n" +
        "}\n";
      let style = document.createElement("style");
      style.type = "text/css";
      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      return style;
    },
  },
};
</script>

<style>
html,
body {
  margin: 0 auto !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

table {
  border-spacing: 0 !important;
  border-collapse: collapse !important;
  table-layout: fixed !important;
  margin: 0 auto !important;
}

*[x-apple-data-detectors],
.unstyle-auto-detected-links *,
.aBn {
  border-bottom: 0 !important;
  cursor: default !important;
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

.widget-text ol,
.widget-text ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.widget-text ol {
  list-style-type: decimal;
}

.widget-text ul {
  list-style-type: disc;
}

.widget-text ol li,
.widget-text ul li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.widget-text ol li p,
.widget-text ul li p {
  display: block;
}

.widget-text,
.widget-text p {
  font-size: 13px;
  word-break: break-word;
  min-height: 12px;
  margin: 0;
  margin-top: 0;
  line-height: 1.8;
}

.widget-text p span {
  font-size: 16px;
  display: inline-flex;
}

.widget-text p>strong,
.widget-text p>em,
.widget-text p>u {
  margin: 0 5px;
}

.widget-text h1,
.widget-text h2,
.widget-text h3,
.widget-text h4,
.widget-text h5,
.widget-text h6 {
  line-height: 1.3;
  margin-bottom: 8px;
}

.widget-text table {
  table-layout: fixed;
  width: 100%;
  border: 1px solid #dee2e6;
}

.widget-text table tbody tr td {
  height: 36px;
  padding: 5px 12px;
  font-size: 16px;
  color: #212529;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  vertical-align: middle;
}

.widget-text table tbody tr td:last-child {
  border-right: none;
}

.widget-text table tbody tr td p {
  margin: 0;
  font-size: 16px;
  color: #212529;
  word-break: break-all;
  white-space: break-spaces;
  word-break: break-word;
}

.widget-text table tbody tr:last-child td {
  border-bottom: none;
}

a {
  color: v-bind("link_style?.color");
  text-decoration: v-bind("link_style?.textDecoration");
}</style>
