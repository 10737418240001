<div class="p-44 bg-white preview-test-box">
  <div ref="previewRender"
    :class="['create-campaign-mode-wrap height-100-300 ', this.previewType ==  'mobile' ? 'mobile-responsive' : '']">
    <!DOCTYPE html>
    <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

    <head ref="headrenderer">
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="x-apple-disable-message-reformatting" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap"
        rel="stylesheet" />
    </head>

    <body :style="global_style">
      <table :style="global_style">
        <tbody>
          <tr>
            <td align="left" style="padding: 0">
              <table align="center" border="0" cellpadding="0" cellspacing="0" class="layer-1" role="presentation"
                width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt">
                <tbody>
                  <tr>
                    <td width="100%" valign="top">
                      <headerRender v-if="templateData?.header?.status" :templateData="templateData" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%"
                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt">
                <tbody>
                  <tr v-for="(container_item, index) in draggable_editor_data" :key="index">
                    <td align="left" style="padding: 0"
                      :style="{backgroundImage: `url(${container_item.properties.style?.src})`,backgroundSize: 'cover', backgroundPosition: 'center',backgroundColor: container_item.properties.color_properties?.backgroundColor,width: '100%' }">
                      <table :class="'in-app-table-container table-container'"
                        :style="{...wrapperContainerStyle,backgroundColor: container_item?.properties?.container_properties?.backgroundColor}"
                        :width="parseInt(wrapperContainerStyle.width, 10)" align="center" border="0">
                        <tbody>
                          <tr v-if="container_item.name === 'box-column-1'">
                            <td valign="top" :style="container_item.properties.style">
                              <div :class="'one-col'" :style="'display: flex; align-items: flex-start;'">
                                <div class="email-col" :style="[container_item.properties.data_content_width_style]">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                                    <tbody>
                                      <tr>
                                        <td
                                          :style="[(container_item.properties.data_content_border_styles?.borderTopWidth != '0px' ||
                                            container_item.properties.data_content_border_styles?.borderRightWidth != '0px' ||
                                            container_item.properties.data_content_border_styles?.borderBottomWidth != '0px' ||
                                            container_item.properties.data_content_border_styles?.borderLeftWidth != '0px') && {
                                            ...container_item.properties.data_content_border_styles,},container_item.properties.data_content_style]"
                                          valign="top">
                                          <div>
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                              width="100%" style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                " v-for="(item, index) in container_item.data_content" :key="index">
                                              <tbody>
                                                <tr>
                                                  <td width="100%" valign="top"
                                                    :style="item.propertiesArray[item.properties.selectedVariationIndex].container_style">
                                                    <component :is="item.render_component" :element="item">
                                                    </component>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="container_item.name === 'box-column-2'">
                            <td valign="top" :style="container_item.properties.style">
                              <div :class="'two-col'" :style="'display: flex; align-items: flex-start;'">
                                <div :class="'email-col'"
                                  :style="[container_item.properties.data_content_left_width_style]">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                                    <tbody>
                                      <tr>
                                        <td :style="[container_item.properties.data_content_left_style,
                                            (container_item.properties.data_content_left_border_styles?.borderTopWidth != '0px' ||
                                            container_item.properties.data_content_left_border_styles?.borderRightWidth != '0px' ||
                                            container_item.properties.data_content_left_border_styles?.borderBottomWidth != '0px' ||
                                            container_item.properties.data_content_left_border_styles?.borderLeftWidth != '0px') && {
                                             ...container_item.properties.data_content_left_border_styles}]"
                                          valign="top">
                                          <div>
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                              width="100%" style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                " v-for="(item, index) in container_item.data_content_left"
                                              :key="index">
                                              <tbody>
                                                <tr>
                                                  <td width="100%" valign="top"
                                                    :style=" item.propertiesArray[item.properties.selectedVariationIndex].container_style">
                                                    <component :is="item.render_component" :element="item">
                                                    </component>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div :class="'email-col'"
                                  :style="[container_item.properties.data_content_right_width_style]">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                                    <tbody>
                                      <tr>
                                        <td :style="[container_item.properties.data_content_right_style,
                                          (container_item.properties.data_content_right_border_styles?.borderTopWidth != '0px' ||
                                          container_item.properties.data_content_right_border_styles?.borderRightWidth != '0px' ||
                                          container_item.properties.data_content_right_border_styles?.borderBottomWidth != '0px' ||
                                          container_item.properties.data_content_right_border_styles?.borderLeftWidth != '0px') && {
                                          ...container_item.properties.data_content_right_border_styles,
                                          },]" valign="top">
                                          <div>
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                              width="100%" style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                " v-for="(item, index) in container_item.data_content_right"
                                              :key="index">
                                              <tbody>
                                                <tr>
                                                  <td width="100%" valign="top"
                                                    :style="item.propertiesArray[item.properties.selectedVariationIndex].container_style">
                                                    <component :is="item.render_component" :element="item">
                                                    </component>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="container_item.name === 'box-column-3'">
                            <td valign="top" :style="container_item.properties.style">
                              <div :class="'three-col'" :style="'display: flex; align-items: flex-start;'">
                                <div :class="'email-col'"
                                  :style="[container_item.properties.data_content_left_width_style]">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                                    <tbody>
                                      <tr>
                                        <td :style="[container_item.properties.data_content_left_style,
                                          (container_item.properties.data_content_left_border_styles?.borderTopWidth != '0px' ||
                                          container_item.properties.data_content_left_border_styles?.borderRightWidth != '0px' ||
                                          container_item.properties.data_content_left_border_styles?.borderBottomWidth != '0px' ||
                                          container_item.properties.data_content_left_border_styles?.borderLeftWidth != '0px') && {
                                          ...container_item.properties.data_content_left_border_styles,
                                          },]" valign="top">
                                          <div>
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                              width="100%" style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                " v-for="(item, index) in container_item.data_content_left"
                                              :key="index">
                                              <tbody>
                                                <tr>
                                                  <td width="100%" valign="top"
                                                    :style="item.propertiesArray[item.properties.selectedVariationIndex].container_style">
                                                    <component :is="item.render_component" :element="item">
                                                    </component>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div :class="'email-col'"
                                  :style="[container_item.properties.data_content_center_width_style]">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                                    <tbody>
                                      <tr>
                                        <td :style="[container_item.properties.data_content_center_style,
                                          (container_item.properties.data_content_center_border_styles?.borderTopWidth
                                          !='0px' ||
                                          container_item.properties.data_content_center_border_styles?.borderRightWidth
                                          !='0px' ||
                                          container_item.properties.data_content_center_border_styles?.borderBottomWidth
                                          !='0px' ||
                                          container_item.properties.data_content_center_border_styles?.borderLeftWidth
                                          !='0px' ) && {
                                          ...container_item.properties.data_content_center_border_styles,
                                          },]" valign="top">
                                          <div>
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                              width="100%" style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                " v-for="(item, index) in container_item.data_content_center"
                                              :key="index">
                                              <tbody>
                                                <tr>
                                                  <td width="100%" valign="top"
                                                    :style="item.propertiesArray[item.properties.selectedVariationIndex].container_style">
                                                    <component :is="item.render_component" :element="item">
                                                    </component>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div :class="'email-col'"
                                  :style="[container_item.properties.data_content_right_width_style]">
                                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%">
                                    <tbody>
                                      <tr>
                                        <td :style="[container_item.properties.data_content_right_style,
                                          (container_item.properties.data_content_right_border_styles?.borderTopWidth != '0px' ||
                                              container_item.properties.data_content_right_border_styles?.borderRightWidth != '0px' ||
                                              container_item.properties.data_content_right_border_styles?.borderBottomWidth != '0px' ||
                                              container_item.properties.data_content_right_border_styles?.borderLeftWidth != '0px') && {
                                              ...container_item.properties.data_content_right_border_styles,
                                              },]" valign="top">
                                          <div>
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                              width="100%" style="
                                                  mso-table-lspace: 0pt;
                                                  mso-table-rspace: 0pt;
                                                " v-for="(item, index) in container_item.data_content_right"
                                              :key="index">
                                              <tbody>
                                                <tr>
                                                  <td width="100%" valign="top"
                                                    :style="item.propertiesArray[item.properties.selectedVariationIndex].container_style">
                                                    <component :is="item.render_component" :element="item">
                                                    </component>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%"
                :style="{backgroundColor: templateData.footer.status? templateData?.footer?.rowColor:'',}"
                style="mso-table-lspace: 0pt; mso-table-rspace: 0pt">
                <tbody>
                  <tr>
                    <td width="100%" valign="top">
                      <table :class="'in-app-table-container table-container'"
                        :style="{
                            ...wrapperContainerStyle,backgroundColor: templateData.footer.status? templateData?.footer?.containerColor:'',                                               }"
                        :width="wrapperContainerStyle.width" align="center" border="0" cellpadding="0" cellspacing="0"
                        role="presentation" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt">
                        <tbody>
                          <tr>
                            <td align="left" style="padding: 0">
                              <footerRender :templateData="templateData" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </body>

    </html>
  </div>
</div>