<template>
  <div
    :align="
      this.element.propertiesArray[
        this.element.properties.selectedVariationIndex
      ].container_style?.textAlign
    "
  >
    <div
      ref="socialWidget"
      class="social-widget"
      :style="{
        ...((element.propertiesArray[element.properties.selectedVariationIndex]
          ?.generalStyle?.borderTopWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.generalStyle?.borderRightWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.generalStyle?.borderBottomWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.generalStyle?.borderLeftWidth != '0px') && {
          ...element.propertiesArray[element.properties.selectedVariationIndex]
            ?.generalStyle,
        }),
        paddingTop: '0',
        display: 'table',
      }"
    >
      <table
        v-for="(item, j) in element.propertiesArray[
          element.properties.selectedVariationIndex
        ].mediaData"
        :key="j"
        :style="{
          marginRight:
            // j + 1 ==
            // element.propertiesArray[element.properties.selectedVariationIndex]
            //   .mediaData?.length
            //   ? '0 !important'
            //   :
            `${
              this.element.propertiesArray[
                this.element.properties.selectedVariationIndex
              ].style?.paddingRight
            } !important`,
        }"
        style="
          display: inline-block;
          border-collapse: collapse;
          table-layout: fixed;
          border-spacing: 0;
          vertical-align: top;
        "
      >
        <tbody>
          <tr style="vertical-align: top">
            <td
              align="left"
              valign="middle"
              :style="{
                paddingTop:
                  element.propertiesArray[
                    element.properties.selectedVariationIndex
                  ].verticalStyle?.spacing || 0 + 'px',
                paddingBottom:
                  element.propertiesArray[
                    element.properties.selectedVariationIndex
                  ].verticalStyle?.spacing || 0 + 'px',
                wordBreak: 'break-word',
                borderCollapse: 'collapse !important',
                verticalAlign: 'top',
                width: element.propertiesArray[
                      element.properties.selectedVariationIndex
                    ].style.height,
              }"
            >
              <a
                :style="styleCssModify(item)"
                target="_blank"
                v-if="item.url"
                :href="item.url"
              >
                <img
                  :height="
                    element.propertiesArray[
                      element.properties.selectedVariationIndex
                    ].style.height
                  "
                  :width="
                    element.propertiesArray[
                      element.properties.selectedVariationIndex
                    ].style.height
                  "
                  :style="
                    element.propertiesArray[
                      element.properties.selectedVariationIndex
                    ].iconImgStyle
                  "
                  :src="item.src"
                  :alt="item.name"
                  :title="item.name"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import settingsRoute from "@/router/settings";
export default {
  name: "socialViewRender",
  inject: ["draggable_editor_data"],
  props: {
    element: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    mainContainerWidth: String,
    responsive: Boolean,
  },
  data() {
    return {
      intervalId: null,
    };
  },
  mounted() {
    if (
      this.columnType &&
      this.draggable_editor_data[this.index][this.columnType][this.childIndex]
        .propertiesArray[this.element.properties.selectedVariationIndex]
        ?.main_width
    ) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.element.properties.selectedVariationIndex
      ].main_width.width = `${this.$refs.socialWidget.offsetWidth}px`;
    }

    this.intervalId = setInterval(() => {
      if (
        this.columnType &&
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.element.properties.selectedVariationIndex]
          ?.main_width
      ) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.element.properties.selectedVariationIndex
        ].main_width.width = `${this.$refs.socialWidget.offsetWidth}px`;
      }
    }, 5000);
  },
  unmounted() {
    clearInterval(this.intervalId);
  },
  methods: {
    styleCssModify(item) {
      // create new style object skipping paddingLeft and paddingRight
      const style = Object.keys(
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].style
      ).reduce((acc, key) => {
        if (key !== "paddingLeft" && key !== "paddingRight") {
          acc[key] =
            this.element.propertiesArray[
              this.element.properties.selectedVariationIndex
            ].style[key];
        }
        return acc;
      }, {});

      if (item.url) {
        return style;
      } else
        return {
          ...style,
          "pointer-events": "none",
        };
    },
  },
};
</script>
