<template>
  <div
    class="button-wrap"
    ref="buttonWidth"
    style="display: inline-block"
    :style="{
      width:
        element.propertiesArray[element.properties.selectedVariationIndex].style
          .width,
    }"
  >
    <a
      :href="hrefFunc()"
      v-if="
        element.name === 'widget_button' &&
        element.propertiesArray[element.properties.selectedVariationIndex]
          .actionType == 'sendEmail'
      "
      :style="{
        ...element.propertiesArray[element.properties.selectedVariationIndex]
          .style,
        width:
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.style?.width === 'auto'
            ? 'auto'
            : element.propertiesArray[element.properties.selectedVariationIndex]
                .button_width?.width
            ? element.propertiesArray[element.properties.selectedVariationIndex]
                .button_width?.width
            : '100%',
        ...((element.propertiesArray[element.properties.selectedVariationIndex]
          ?.borderStyles?.borderTopWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderRightWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderBottomWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderLeftWidth != '0px') && {
          ...element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles,
        }),
      }"
    >
      {{
        element.propertiesArray[element.properties.selectedVariationIndex]
          .buttonText
      }}
    </a>

    <a
      :href="
        element.propertiesArray[
          element.properties.selectedVariationIndex
        ].buttonUrl.includes('https://')
          ? element.propertiesArray[element.properties.selectedVariationIndex]
              .buttonUrl
          : element.propertiesArray[element.properties.selectedVariationIndex]
              .buttonUrl == ''
          ? ''
          : 'https://' +
            element.propertiesArray[element.properties.selectedVariationIndex]
              .buttonUrl
      "
      v-if="
        element.name === 'widget_button' &&
        element.propertiesArray[element.properties.selectedVariationIndex]
          .actionType == 'openWebsite'
      "
      :target="
        element.propertiesArray[element.properties.selectedVariationIndex]
          .urlTarget == '_self'
          ? ''
          : '_blank'
      "
      :style="{
        ...element.propertiesArray[element.properties.selectedVariationIndex]
          .style,
        width:
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.style?.width === 'auto'
            ? 'auto'
            : element.propertiesArray[element.properties.selectedVariationIndex]
                .button_width?.width
            ? element.propertiesArray[element.properties.selectedVariationIndex]
                .button_width?.width
            : '100%',
        ...((element.propertiesArray[element.properties.selectedVariationIndex]
          ?.borderStyles?.borderTopWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderRightWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderBottomWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderLeftWidth != '0px') && {
          ...element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles,
        }),
      }"
    >
      {{
        element.propertiesArray[element.properties.selectedVariationIndex]
          .buttonText
      }}
    </a>
    <a
      v-if="
        element.name === 'widget_button' &&
        element.propertiesArray[element.properties.selectedVariationIndex]
          .actionType == 'insertTag'
      "
      :target="
        element.propertiesArray[element.properties.selectedVariationIndex]
          .urlTarget == '_self'
          ? ''
          : '_blank'
      "
      :style="{
        ...element.propertiesArray[element.properties.selectedVariationIndex]
          .style,
        width:
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.style?.width === 'auto'
            ? 'auto'
            : element.propertiesArray[element.properties.selectedVariationIndex]
                .button_width?.width
            ? element.propertiesArray[element.properties.selectedVariationIndex]
                .button_width?.width
            : '100%',
        ...((element.propertiesArray[element.properties.selectedVariationIndex]
          ?.borderStyles?.borderTopWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderRightWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderBottomWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderLeftWidth != '0px') && {
          ...element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles,
        }),
      }"
    >
      {{
        element.propertiesArray[element.properties.selectedVariationIndex]
          .buttonText
      }}
    </a>
  </div>
</template>

<script>
import { throttleFunction } from "@/utils/helper";

export default {
  name: "buttonViewRender",
  inject: ["draggable_editor_data"],
  components: {},
  props: {
    element: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    mainContainerWidth: String,
    responsive: Boolean,
    emailMailTo: "",
  },
  data() {
    return {
      windowsWidth: null,
    };
  },
  watch: {
    $props: {
      handler() {
        this.buttonWidthModifyHandler();
      },
      deep: true,
      immediate: false,
    },

    mainContainerWidth() {
      this.buttonWidthModifyHandler();
    },
  },
  created() {
    this.buttonWidthModifyHandler = throttleFunction(
      this.modify_button_width,
      0
    );

    window.addEventListener(
      "resize",
      throttleFunction(this.widthChangeHandler, 0)
    );
  },
  mounted() {
    this.buttonWidthModifyHandler();
  },

  unmounted() {
    window.removeEventListener("resize", this.widthChangeHandler);
  },

  methods: {
    widthChangeHandler() {
      this.buttonWidthModifyHandler();
    },
    modify_button_width() {
      if (
        this.columnType &&
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.element.properties.selectedVariationIndex]
          .button_width
      ) {
        if (
          this.element.name === "widget_button" &&
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].actionType == "sendEmail"
        ) {
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[
            this.element.properties.selectedVariationIndex
          ].button_width.width = this.$refs.buttonWidth?.clientWidth + "px";
        }

        if (
          this.element.name === "widget_button" &&
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].actionType == "openWebsite"
        ) {
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[
            this.element.properties.selectedVariationIndex
          ].button_width.width = this.$refs.buttonWidth?.clientWidth + "px";
        }
      }
    },
    hrefFunc() {
      var mailto = "";
      var subject = "";
      var body = "";
      if (
        this.element?.propertiesArray[
          this.element.properties.selectedVariationIndex
        ]?.emailsForSendEmail != ""
      ) {
        mailto =
          "mailto:" +
          this.element?.propertiesArray[
            this.element.properties.selectedVariationIndex
          ]?.emailsForSendEmail;
      }
      if (
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].emailSubject != ""
      ) {
        subject =
          "?subject=" +
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].emailSubject;
      }
      if (
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].emailBody != ""
      ) {
        body =
          "&body=" +
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].emailBody;
      }
      if (mailto != "") {
        return (
          (mailto != "" ? mailto : "mailto: ") +
          (subject != "" ? subject : "?subject=untitled") +
          (body != "" ? body : "&body= untitled")
        );
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped></style>
