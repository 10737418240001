<template>
  <div class="widget-htmlEditor" :style="{
    ...element.propertiesArray[element.properties.selectedVariationIndex]
      .style,
    ...((element.propertiesArray[element.properties.selectedVariationIndex]
      ?.borderStyles?.borderTopWidth != '0px' ||
      element.propertiesArray[element.properties.selectedVariationIndex]
        ?.borderStyles?.borderRightWidth != '0px' ||
      element.propertiesArray[element.properties.selectedVariationIndex]
        ?.borderStyles?.borderBottomWidth != '0px' ||
      element.propertiesArray[element.properties.selectedVariationIndex]
        ?.borderStyles?.borderLeftWidth != '0px') && {
      ...element.propertiesArray[element.properties.selectedVariationIndex]
        ?.borderStyles,
    }),
  }" v-if="element.name === 'widget_html_editor'" v-html="
  element.propertiesArray[element.properties.selectedVariationIndex].content
"></div>
</template>

<script>
export default {
  name: "htmlEditorViewRender",
  components: {},
  props: {
    element: Object,
  },
  methods: {},
};
</script>

<style scoped>

</style>
