<template src="./CampaignDetails.html" lang=""></template>
<style lang="scss">
@import "./CampaignDetails.scss";
</style>

<script>
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import { RadioButton } from "@progress/kendo-vue-inputs";
import Breadcrumb from "@/components/common/AppBreadCrumbs/BreadCrumbs.vue";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import WarningModal from "../warningModal/WarningModal.vue";
import {
  getEmailCampaignDetails,
  updateEmailCampaign,
  updateEmailCampaignStatus,
} from "@/services/clubDetails/emailCampaign.service";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import RescheduleCampaignModal from "../rescheduleCampaignModal/RescheduleCampaignModal.vue";
import moment from "moment";
import { getEmailCampaignTemplate } from "@/services/clubDetails/emailTemplate.service";
import EmailTemplateMobileAndDesktopPreview from "../emailTemplateMobileAndDesktopPreview/EmailTemplateMobileAndDesktopPreview.vue";
export default {
  name: "CampaignDetails",
  components: {
    "k-input": Input,
    kbutton: Button,
    Breadcrumb,
    radiobutton: RadioButton,
    AppSnackBar: AppSnackBar,
    radiogroup: RadioGroup,
    WarningModal,
    AppLoader,
    RescheduleCampaignModal,
    EmailTemplateMobileAndDesktopPreview,
  },
  data() {
    return {
      moduleCode: "emailCampaign",
      itemCode: "allCampaign",
      templatePreviewType: "desktop",
      isCampaignSubjectLineViewMore: false,
      emailTemplateJson: {},
      testMetricItems: [
        { id: 1, text: "Open Rate" },
        { id: 2, text: "Click Rate" },
      ],
      testMetricValue: {},
      testDurationItems: [
        { id: 2, text: "2 Hours", value: 120 },
        { id: 4, text: "4 Hours", value: 240 },
        { id: 8, text: "8 Hours", value: 480 },
        { id: 12, text: "16 Hours", value: 960 },
        { id: 24, text: "1 Day", value: 1440 },
        { id: 48, text: "2 Days", value: 2880 },
        { id: 96, text: "4 Days", value: 5760 },
        { id: 168, text: "1 Week", value: 10080 },
      ],
      testDurationValue: {},
      campaignStatus: {
        1: "Draft",
        2: "Live",
        3: "Scheduled",
        4: "Paused",
        5: "Completed",
        6: "Cancelled",
        7: "Scheduled",
      },

      campaignStatusColor: {
        1: "draft",
        2: "live",
        3: "scheduled",
        4: "paused",
        5: "live",
        6: "",
        7: "scheduled",
      },

      dateStartingMessage: {
        2: "Sent on : ",
        3: "Scheduled to be sent on : ",
        4: "Paused on : ",
        5: "Completed on : ",
        6: "Cancelled on : ",
        7: "Campaign locked on : ",
      },

      campaignId: this.$route.params.campaignId,
      campaignDetails: {},
      isLoading: false,
      statusChangeLoading: false,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      clubName: this.$route.query.club_name,
      pauseCamaignWarningModalVisibility: false,

      pauseCampaignWarningModalDetails: {
        title: "Pause Campaign",
        message:
          "You are about to pause your campaign that is running. No recipients will enter the campaign if the campaign is paused and no further analytics will be generated. Are you sure you want to pause the campaign?",
        confirmButton: "Pause Campaign",
        cancelButton: "Cancel",
      },

      endCampaignWarningModalVisibility: false,
      endCampaignWarningModalDetails: {
        title: "End Campaign",
        message:
          "You are about to end your campaign. This campaign will stop and no further analytics will be generated. Are you sure you want to end your campaign?",
        confirmButton: "End Campaign",
        cancelButton: "Cancel",
      },

      cancelSendCampaignWarningModalVisibility: false,
      cancelSendCampaignWarningModalDetails: {
        title: "Cancel Campaign",
        message:
          "You are about to cancel your scheduled campaign. This campaign will saved as draft and you have to publish it again to go live. Are you sure you want to cancel this campaign?",
        confirmButton: "Cancel Campaign",
        cancelButton: "Cancel",
      },

      resumeCampaignWarningModalVisibility: false,

      resumeCampaignWarningModalDetails: {
        title: "Resume Campaign",
        message:
          "You are about to resume your campaign that was paused. Are you sure you want to resume your campaign?",
        confirmButton: "Resume Campaign",
        cancelButton: "Cancel",
      },

      rescheduleCampaignModalVisibility: false,
    };
  },

  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }

    this.$store.dispatch("getTimeZonesList");
    const payload = {
      id: this.$route.params.campaignId,
    };

    this.getCampaignDetails(payload);
  },

  unmounted() {
    this.$store.commit("SET_EMAIL_CAMPAIGN_NAME", "");
  },

  computed: {
    timeZoneName() {
      return this.$store.state.commonEmailCampaign.timeZoneList.find((item) => {
        return item.id === this?.campaignDetails?.timeZoneId;
      })?.name;
    },
    formattedCampaignName() {
      return this.generateName(this.campaignDetails?.campaignName);
    },
  },

  methods: {
    backToFlowHandler() {
      this.$router.push({
        name: "viewFlowDetails",
        params: {
          ...this.$route.params,
          flowId: this.$route.query.flowId,
          flowAutomationName: this.$route.query.flowAutomationName,
        },
        query: {
          flowAutomationName: this.$route.query.flowAutomationName,
        },
      });
    },
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    templateViewToggle(type) {
      this.templatePreviewType = type;
    },
    toggleSubjectLineViewMore() {
      this.isCampaignSubjectLineViewMore = !this.isCampaignSubjectLineViewMore;
    },
    getProperDate(date) {
      return `${this.getDate(new Date(date))} at ${this.timeFormatAMPM(
        new Date(date)
      )}  ( ${this.timeZoneName} )`;
    },
    getDate(date) {
      if (date != null) {
        return moment(date).format("MMM Do YYYY");
      }
    },

    timeFormatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes.toString().padStart(2, "0");
      let strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },

    navigateToCampaignEdit() {
      this.$router.push({
        name: "CampaignEdit",
        params: {
          ...this.$route.params,
          campaignId: this?.campaignDetails?.campaignId,
          campaignName: this?.campaignDetails?.campaignName,
        },
        query: {
          type: 2,
        },
      });
    },

    getTemplateDetails(payload) {
      getEmailCampaignTemplate(payload)
        .then((res) => {
          this.emailTemplateJson = JSON.parse(
            res?.data?.data?.templateJsonObject
          );
          this.isEmailTemplateSelected = true;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },

    getCampaignDetails(payload, isFromUpdate = false) {
      isFromUpdate ? (this.isLoading = false) : (this.isLoading = true);
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };
      getEmailCampaignDetails(payload)
        .then((res) => {
          this.campaignDetails = res.data.data;

          this.testDurationValue = this.testDurationItems.find(
            (item) =>
              item.value ===
              this.campaignDetails.campaignOptimization.testDuration
          );

          this.testMetricValue = this.testMetricItems.find(
            (item) =>
              item.id ===
              this.campaignDetails.campaignOptimization.campaignTestMetric
          );

          if (this.campaignDetails?.emailCampaignTemplateId) {
            this.getTemplateDetails({
              id: this.campaignDetails?.emailCampaignTemplateId,
              clubId: this.$route.params.id,
            });
          } else {
            this.isLoading = false;
          }
          this.$store.commit(
            "SET_EMAIL_CAMPAIGN_NAME",
            this.campaignDetails?.campaignName
          );
          // this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          // ;
        });
    },

    resumeCampaignHandler() {
      if (
        this?.campaignDetails?.previousCampaignStatus == 3 &&
        this?.campaignDetails?.campaignStatus == 4
      ) {
        this.rescheduleCampaignModalVisibility = true;
        return;
      }

      if (
        this?.campaignDetails?.previousCampaignStatus == 2 &&
        this?.campaignDetails?.campaignStatus == 4
      ) {
        this.resumeCampaignWarningModalVisibility = true;
        return;
      }

      this.resumeCampaignWarningModalVisibility = true;
    },

    onCancelResumeCampaign() {
      this.resumeCampaignWarningModalVisibility = false;
    },

    onConfirmResumeCampaign() {
      this.resumeCampaignWarningModalVisibility = false;
      const payload = {
        campaignId: this.campaignId,
        campaignStatus: 2,
      };
      this.campaignStatusChangeHandler(payload);
    },

    puaseCampaignHandler() {
      if (this.campaignDetails?.campaignStatus == 3) {
        this.pauseCamaignWarningModalVisibility = true;
        this.pauseCampaignWarningModalDetails.message =
          "You are about to pause your campaign that is scheduled. You may have to reschedule if you want to resume this campaign. Are you sure you want to pause this scheduled campaign?";
      } else {
        this.pauseCamaignWarningModalVisibility = true;
      }
    },

    onCancelPauseCampaign() {
      this.pauseCamaignWarningModalVisibility = false;
    },

    onConfirmPauseCampaign() {
      this.pauseCamaignWarningModalVisibility = false;
      const payload = {
        campaignId: this.campaignId,
        campaignStatus: 4,
      };
      this.campaignStatusChangeHandler(payload);
    },

    cancelSendCampaignHandler() {
      this.cancelSendCampaignWarningModalVisibility = true;
    },

    onCancelSendCampaign() {
      this.cancelSendCampaignWarningModalVisibility = false;
    },

    onConfirmCancelSendCampaign() {
      this.cancelSendCampaignWarningModalVisibility = false;
      const payload = {
        campaignId: this.campaignId,
        campaignStatus: 6,
      };
      this.campaignStatusChangeHandler(payload);
    },

    endCampaignHandler() {
      this.endCampaignWarningModalVisibility = true;
    },

    onCancelEndCampaign() {
      this.endCampaignWarningModalVisibility = false;
    },

    onConfirmEndCampaign() {
      this.endCampaignWarningModalVisibility = false;
      const payload = {
        campaignId: this.campaignId,
        campaignStatus: 5,
      };
      this.campaignStatusChangeHandler(payload);
    },

    onCancelRescheduleCampaign() {
      this.rescheduleCampaignModalVisibility = false;
    },

    onConfirmRescheduleCampaign(payload) {
      this.rescheduleCampaignModalVisibility = false;
      this.campaignUpdateHandler(payload);
    },
    generateName(name) {
      if (!name) {
        return "";
      }
      return name.length > 70 ? name.substring(0, 70) + "..." : name;
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },
    doNavigateToCampaignHomepage(type) {
      if (type == 1) {
        this.$router.push({
          name: "AllCampaign",
        });
      } else if (type == 2) {
        this.$router.push({
          name: "flowAutomation",
        });
      }
    },

    navigateToViewAnalytics() {
      this.$store.commit(
        "SET_EMAIL_CAMPAIGN_NAME",
        this?.campaignDetails?.campaignName
      );
      this.$router.push({
        name: "CampaignAnalytics",
        params: {
          ...this.$route.params,
          campaignId: this?.campaignDetails?.campaignId,
        },
        query: {
          type: 2,
          campaignName: this?.campaignDetails?.campaignName,
        },
      });
    },

    statusChangeSuccessMessageGenerator(status) {
      if (status == 4) {
        return "Campaign has been paused.";
      } else if ([2, 3].includes(status)) {
        return "Campaign has been resumed.";
      } else if (status == 5) {
        return "Campaign has been ended.";
      } else if (status == 6) {
        return "Campaign has been cancelled.";
      }
    },

    campaignStatusChangeHandler(payload) {
      this.statusChangeLoading = true;
      let message = this.statusChangeSuccessMessageGenerator(
        payload.campaignStatus
      );

      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };

      updateEmailCampaignStatus(payload)
        .then((res) => {
          this.statusChangeLoading = false;
          this.showNotification(message, "success");
          const payload = {
            id: this.$route.params.campaignId,
          };
          this.getCampaignDetails(payload, true);
        })
        .catch((err) => {
          this.statusChangeLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },

    campaignUpdateHandler(payload) {
      this.statusChangeLoading = true;
      updateEmailCampaign(payload)
        .then((res) => {
          this.statusChangeLoading = false;
          this.showNotification(
            "Campaign has been rescheduled to selected date and time.",
            "success"
          );
          this.getCampaignDetails(
            {
              id: this.$route.params.campaignId,
            },
            true
          );
        })
        .catch((err) => {
          this.statusChangeLoading = false;
          this.showNotification("Something went wrong.", "error");
        });
    },
  },
};
</script>
<style lang=""></style>
