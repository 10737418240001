<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>

<app-loader v-if="isLoading || statusChangeLoading"></app-loader>
<div class="dashboard-right-inner">
    <Breadcrumb :id="$route.params.id" />

    <div v-if="!isLoading" class="max-height-100--60 overflow-y-auto">

        <div v-if="!isLoading" class="dashboard-header">
            <div class="d-flex flex-wrap justify-content-between align-items-center">
                <div>
                    <div class="d-flex align-items-center mb-1">
                        <h2 class="font-28 font-w-300 mb-0 me-3">{{formattedCampaignName}}</h2>
                        <span
                            :class="['campaign-status font-12 radius-4',campaignStatusColor[campaignDetails?.campaignStatus]]">{{campaignStatus[campaignDetails?.campaignStatus]}}</span>
                    </div>
                    
                    <div class="d-flex flex-wrap justify-content-start align-items-center">
                        <p v-if="campaignDetails?.campaignGroup != 2" class="font-14 color-light2 m-0">{{campaignDetails?.campaignStatus == 2 &&
                            campaignDetails?.previousCampaignStatus == 4 ? 'Resumed on : ' :
                            dateStartingMessage?.[campaignDetails?.campaignStatus]}}
                            {{getProperDate(campaignDetails?.campaignStatus == 3 ? campaignDetails?.dateOfSchedule :
                            campaignDetails?.lastDateOfAction)}}</p>
                        <div v-if="[4].includes(campaignDetails?.campaignStatus) && campaignDetails?.campaignGroup != 2"
                            class="d-flex justify-content-start align-items-center w-100-vsm">
                            <div class="dot purple h-10 w-10 radius-100 ms-0 ms-sm-2 me-2 d-none d-sm-inline-block">
                            </div>
                            <kbutton :class="'p-0 btn-hover-none btn-icon-s-10'" :size="'medium'" :icon="'play'"
                                :disabled="this.hasEditAccess() == false" :theme-color="'base'"
                                @click="resumeCampaignHandler" :fill-mode="'flat'">Resume Campaign
                            </kbutton>
                        </div>

                        <div v-if="[2,3].includes(campaignDetails?.campaignStatus) && campaignDetails?.campaignGroup != 2"
                            class="d-flex justify-content-start align-items-center w-100-vsm">
                            <div class="dot purple h-10 w-10 radius-100 ms-0 ms-sm-2 me-2 d-none d-sm-inline-block">
                            </div>
                            <kbutton :class="'p-0 btn-hover-none btn-icon-s-10'" :size="'medium'" :icon="'pause'"
                                :disabled="this.hasEditAccess() == false" :theme-color="'base'"
                                @click="puaseCampaignHandler" :fill-mode="'flat'">Pause Campaign
                            </kbutton>
                        </div>

                        <div v-if="[2].includes(campaignDetails?.campaignStatus) && campaignDetails?.campaignGroup != 2"
                            class="d-flex justify-content-start align-items-center w-100-vsm">
                            <div class="dot purple h-10 w-10 radius-100 ms-0 ms-sm-2 me-2 d-none d-sm-inline-block">
                            </div>
                            <kbutton :class="'p-0 btn-hover-none btn-icon-s-14'" :size="'medium'"
                                :disabled="this.hasEditAccess() == false" :icon="'close-outline'" :theme-color="'error'"
                                @click="endCampaignHandler" :fill-mode="'flat'">End Campaign
                            </kbutton>
                        </div>

                        <div v-if="[3].includes(campaignDetails?.campaignStatus) && campaignDetails?.campaignGroup != 2"
                            class="d-flex justify-content-start align-items-center w-100-vsm">
                            <div class="dot purple h-10 w-10 radius-100 ms-0 ms-sm-2 me-2 d-none d-sm-inline-block">
                            </div>
                            <kbutton :class="'p-0 btn-hover-none btn-icon-s-14'" :size="'medium'"
                                :disabled="this.hasEditAccess() == false" :icon="'close-outline'" :theme-color="'error'"
                                @click="cancelSendCampaignHandler" :fill-mode="'flat'">Cancel Send
                            </kbutton>
                        </div>

                        <div v-if="[4].includes(campaignDetails?.campaignStatus) && [3].includes(campaignDetails?.previousCampaignStatus) && campaignDetails?.campaignGroup != 2"
                            class="d-flex justify-content-start align-items-center w-100-vsm">
                            <div class="dot purple h-10 w-10 radius-100 ms-0 ms-sm-2 me-2 d-none d-sm-inline-block">
                            </div>
                            <kbutton :class="'p-0 btn-hover-none btn-icon-s-10'" :size="'medium'" :icon="'pencil'"
                                :disabled="this.hasEditAccess() == false" :theme-color="'base'"
                                @click="navigateToCampaignEdit" :fill-mode="'flat'">Edit Campaign
                            </kbutton>
                        </div>
                    </div>
                </div>

                <div class="mt-2 mt-xl-0 w-100-vsm">
                    <kbutton v-if="this.$route.query?.from_flow_automation" :fill-mode="'flat'" :class="'ms-0 pl-0-xl color-black w-100-vsm'" :theme-color="'primary'"
                        @click="backToFlowHandler">
                        Back to Flow
                    </kbutton>

                    <kbutton v-else :fill-mode="'flat'" :class="'ms-0 pl-0-xl color-black w-100-vsm'" :theme-color="'primary'"
                    @click="doNavigateToCampaignHomepage(campaignDetails?.campaignGroup)">
                    Return to {{campaignDetails?.campaignGroup == 1?'Campaigns':'Flow Automation'}}
                    </kbutton>
                    
                    <kbutton
                        v-if="[2,4].includes(campaignDetails?.campaignStatus) && [1,2,3,4].includes(campaignDetails?.previousCampaignStatus) || [5].includes(campaignDetails?.campaignStatus)"
                        :fill-mode="'outline'" :class="'ms-0 ms-sm-3 h-38 w-100-vsm'" :theme-color="'primary'"
                        :disabled="this.hasGetAccess() == false" @click="navigateToViewAnalytics">
                        View Analytics
                    </kbutton>
                </div>
            </div>
        </div>

        <!--dashboard-content-->
        <div v-if="!isLoading"
            class="dashboard-content my-campaign-dashboard-content max-height-100-247 p-0 h-100 min-h-100vh--174">
            <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                <div class="my-campaign-left">
                    <div class="p-32">

                        <div class="my-campaign-info-box border-bottom-normal-1 pb-2 mb-32"
                            v-if="campaignDetails?.campaignSubject?.length <= 1">
                            <h6 class="color-gray font-16 d-block mb-2">Subject Line</h6>
                            <p class="color-black font-16 mb-0">
                                {{campaignDetails?.campaignSubject[0]?.subjectLine || 'No subject line defined'}}
                            </p>
                        </div>

                        <div class="my-campaign-info-box border-bottom-normal-1 pb-2 mb-32"
                            v-if="campaignDetails?.campaignSubject?.length > 1 && !isCampaignSubjectLineViewMore">
                            <h6 class="color-gray font-16 d-block mb-2">Subject Line 1</h6>
                            <div class="d-flex justify-content-between">
                                <p class="color-black font-16 mb-0">
                                    {{campaignDetails?.campaignSubject[0]?.subjectLine || 'No subject line defined'}}
                                </p>
                                <kbutton @click="toggleSubjectLineViewMore" :fill-mode="'flat'" :class="'h-38'"
                                    :size="'medium'" :icon="''" :theme-color="'primary'">View more
                                </kbutton>
                            </div>
                        </div>

                        <div class="my-campaign-info-box border-bottom-normal-1 pb-2 mb-32"
                            v-if="campaignDetails?.campaignSubject?.length > 1  && isCampaignSubjectLineViewMore"
                            v-for="(item, index) in campaignDetails?.campaignSubject">
                            <h6 class="font-16 opacity-5">Subject Line {{index + 1}} </h6>
                            <div class="d-flex justify-content-between">
                                <p class="color-black font-16 mb-0">
                                    {{item?.subjectLine || 'No subject line defined'}}
                                </p>
                                <kbutton v-if="index == 0" @click="toggleSubjectLineViewMore" :fill-mode="'flat'"
                                    :class="'h-38'" :size="'medium'" :icon="''" :theme-color="'primary'">
                                    View less
                                </kbutton>
                            </div>
                        </div>


                        <div class="my-campaign-info-box border-bottom-normal-1 pb-2 mb-32">
                            <label class="color-gray font-16 d-block mb-2">Preview Text</label>
                            <p class="color-black font-16 mb-0">
                                {{campaignDetails?.previewText || 'No preview text defined'}}
                            </p>
                        </div>

                        <div class="my-campaign-info-box border-bottom-normal-1 pb-2 mb-32">
                            <label class="color-gray font-16 d-block mb-2">Sender Name</label>
                            <p class="color-black font-16 mb-0">{{campaignDetails?.senderName}}</p>
                        </div>
                        <div class="my-campaign-info-box border-bottom-normal-1 pb-2 mb-32">
                            <label class="color-gray font-16 d-block mb-2">Sender Email Address</label>
                            <p class="color-black font-16 mb-0">{{campaignDetails?.senderEmail}}</p>
                        </div>
                        <div class="my-campaign-info-box border-bottom-normal-1 pb-2 mb-32">

                            <label class="color-gray font-16 d-block mb-2">Recipients</label>

                            <h6 v-if="campaignDetails?.campaignAudienceList?.length ==  0 && campaignDetails?.campaignSegment?.length ==  0"
                                class="font-16 opacity-5">No recipients selected
                                yet.
                            </h6>

                            <div v-else>
                                <div v-if="campaignDetails?.campaignAudienceList?.length != 0 || campaignDetails?.campaignSegment?.length != 0 "
                                    class="d-flex flex-wrap align-items-center">
                                    <h6 class="font-16 me-2">Send To</h6>
                                    <div class="d-flex flex-wrap align-content-center"
                                        v-for="(list, index) in campaignDetails?.campaignAudienceList">
                                        <span class="my-campaign-recipients">{{list?.audienceListName}}
                                            (List)
                                        </span>
                                    </div>
                                    <div class="d-flex flex-wrap align-content-center"
                                        v-for="(segment, index) in campaignDetails?.campaignSegment">
                                        <span class="my-campaign-recipients">{{segment?.segmentName}}
                                            (Segment)
                                        </span>
                                    </div>
                                </div>

                                <hr v-if="campaignDetails?.campaignAudienceListExcept?.length != 0 || campaignDetails?.campaignSegmentExcept?.length != 0 "
                                    class="mt-8 mb-24">

                                <div v-if="campaignDetails?.campaignAudienceListExcept?.length != 0 || campaignDetails?.campaignSegmentExcept?.length != 0 "
                                    class="d-flex flex-wrap align-items-center">

                                    <h6 class="font-16 me-2">Don't Send To</h6>
                                    <div class="d-flex flex-wrap align-content-center"
                                        v-for="(list, index) in campaignDetails?.campaignAudienceListExcept">
                                        <span class="my-campaign-recipients">{{list?.audienceListName}}
                                            (List)
                                        </span>
                                    </div>
                                    <div class="d-flex flex-wrap align-content-center"
                                        v-for="(segment, index) in  campaignDetails?.campaignSegmentExcept">
                                        <span class="my-campaign-recipients">{{segment?.segmentName}}
                                            (Segment)
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="campaignDetails?.campaignOptimization?.id"
                            class="my-campaign-info-box border-bottom-normal-1 pb-2 mb-32">
                            <label class="color-gray font-16 d-block mb-2">Advanced Optimization Settings</label>

                            <div v-if="campaignDetails?.campaignOptimization?.campaignOptimizationType == 1">
                                <h6 class="font-16 opacity-5"><b>Testing Strategy:</b> Campaign will be tested on
                                    {{campaignDetails?.campaignOptimization?.ratioCount}} emails per combination for
                                    {{testDurationValue?.text}}
                                    to determine the best performing combination based on {{testMetricValue?.text}}.
                                    Best performing combination
                                    will be sent to the rest of the recipients who aren’t part of the test phase.</h6>
                            </div>

                            <div v-if="campaignDetails?.campaignOptimization?.campaignOptimizationType == 2">
                                <h6 class="font-16 opacity-5"><b>Testing Strategy:</b> Campaign will be tested on
                                    {{campaignDetails?.campaignOptimization?.ratioCount}}% of
                                    recipients for {{testDurationValue?.text}} to determine the best performing
                                    combination based on {{testMetricValue?.text}}. Best
                                    performing combination will be sent to the rest of the recipients who aren’t part of
                                    the test phase.

                                    .</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="my-campaign-right bg-white">
                    <div class="bg-white h-100">
                        <div
                            class="bg-white create-campaign-tab-wrap position-relative d-flex justify-content-between align-items-center p-8">
                            <div class="create-campaign-tab-nav d-flex justify-content-start align-items-center">
                                <kbutton @click="templateViewToggle('desktop')" :fill-mode="'none'"
                                    :class="templatePreviewType == 'desktop' ? 'active' : ''">
                                    Desktop
                                </kbutton>
                                <kbutton @click="templateViewToggle('mobile')" :fill-mode="'none'"
                                    :class="templatePreviewType == 'mobile' ? 'active' : ''">
                                    Mobile</kbutton>
                            </div>
                        </div>
                        <EmailTemplateMobileAndDesktopPreview v-if="emailTemplateJson?.draggable_editor_data"
                            :previewType="templatePreviewType" ref="previewRender"
                            :draggable_editor_data="emailTemplateJson?.draggable_editor_data"
                            :global_style="emailTemplateJson?.global_style" :link_style="emailTemplateJson?.link_style"
                            :wrapperContainerStyle="emailTemplateJson?.wrapperContainerStyle"
                            :templateData="emailTemplateJson?.templateData" />
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

<WarningModal v-if="pauseCamaignWarningModalVisibility" :modalDetails="pauseCampaignWarningModalDetails"
    :onCancel="onCancelPauseCampaign" :onConfirm="onConfirmPauseCampaign" />

<WarningModal v-if="pauseCamaignForScheduleWarningModalVisibility"
    :modalDetails="pauseCampaignForScheduleWarningModalDetails" :onCancel="onCancelPauseCampaign"
    :onConfirm="onConfirmPauseCampaign" />

<WarningModal v-if="endCampaignWarningModalVisibility" :modalDetails="endCampaignWarningModalDetails"
    :onCancel="onCancelEndCampaign" :onConfirm="onConfirmEndCampaign" />

<WarningModal v-if="cancelSendCampaignWarningModalVisibility" :modalDetails="cancelSendCampaignWarningModalDetails"
    :onCancel="onCancelSendCampaign" :onConfirm="onConfirmCancelSendCampaign" />

<WarningModal v-if="resumeCampaignWarningModalVisibility" :modalDetails="resumeCampaignWarningModalDetails"
    :onCancel="onCancelResumeCampaign" :onConfirm="onConfirmResumeCampaign" />

<RescheduleCampaignModal v-if="rescheduleCampaignModalVisibility" :prePayload="campaignDetails"
    :onCancel="onCancelRescheduleCampaign" :onConfirm="onConfirmRescheduleCampaign" />