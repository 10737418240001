<template>
  <table cellspacing="0" cellpadding="0" border="0" role="presentation" width="100%" align="center"
    style="table-layout: fixed; width: 100%">
    <tr>
      <th width="100%" valign="top" style="font-weight: normal">
        <table cellspacing="0" cellpadding="0" border="0" role="presentation" style="table-layout: fixed; width: 100%">
          <tr>
            <td valign="top">
              <table :width="tableWidthValue" cellspacing="0" cellpadding="0" border="0" role="presentation">
                <tr>
                  <td align="center">
                    <!-- Outer table for navigation items -->
                    <table cellspacing="0" cellpadding="0" border="0" role="presentation" align="center"
                      style="table-layout: fixed" ref="navigationTable">
                      <tr>
                        <td>
                          <table width="100%" cellspacing="0" cellpadding="0" border="0" role="presentation">
                            <!-- Dynamically generated rows -->
                            <tr v-for="(row, rowIndex) in calculatedRows" :key="rowIndex">
                              <td v-for="(item, itemIndex) in row" :key="itemIndex" :colspan="item.isFullRow ? 5 : 1"
                                width="auto" valign="top" :style="getItemStyle(item)"
                                :align="getTextStyle(item)?.textAlign">
                                <table cellspacing="0" cellpadding="0" border="0" role="presentation"
                                  style="table-layout: fixed; margin: 0 !important;">
                                  <tr>
                                    <td :style="getItemPadding(item)">
                                      <table cellspacing="0" cellpadding="0" border="0" role="presentation">
                                        <tr>
                                          <th width="100%" valign="top" style="font-weight: normal">
                                            <table cellspacing="0" cellpadding="0" border="0" role="presentation"
                                              style="table-layout: fixed">
                                              <tr>
                                                <td align="center" valign="top" class="nl2go-default-textstyle">
                                                  <div>
                                                    <a style="display: inline-block;" :style="getTextStyle(item)
                                                      " :href="ensureHttps(item.url)
                                                        ">
                                                      {{ item?.name }}
                                                    </a>
                                                  </div>
                                                </td>
                                              </tr>
                                            </table>
                                          </th>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </th>
    </tr>
  </table>
</template>

<script>
export default {
  name: "DynamicNavigationTable",
  props: {
    element: {
      type: Object,
      required: true,
    },
    itemPadding: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      maxWidth: null,
      getTextWidthData: null
    };
  },
  mounted() {
    this.element.propertiesArray[
      this.element.properties.selectedVariationIndex
    ].containerMaxWidth = this.$refs.navigationTable.offsetWidth;
    this.maxWidth = this.$refs.navigationTable.offsetWidth;
    this.element.propertiesArray[
      this.element.properties.selectedVariationIndex
    ].tableWidth = this.$refs.navigationTable.offsetWidth;
  },
  computed: {
    calculatedPadding() {
      let columnSpace =
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ]?.columnSpace || 0;
      return {
        padding: `${columnSpace}px`,
      };
    },
    tableWidthValue() {
      let tableWidth =
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ]?.tableWidth || 0;
      return tableWidth > 0 ? `${tableWidth}px` : "100%"
    },
    calculatedRows() {
      let navigationItems =
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].navigations;
      let rows = [];
      let currentRow = [];
      let currentWidth = 0;

      let fontSize = 16;
      let fontFamily =
        this.element.propertiesArray[
          this.element.properties.selectedVariationIndex
        ].font_family.value;

      navigationItems.forEach((item) => {
        let itemWidth =
          this.getTextWidth(item.name, fontSize, fontFamily) + this.itemPadding;

        if (itemWidth > this.maxWidth) {
          if (currentRow.length > 0) {
            rows.push(currentRow);
            currentRow = [];
            currentWidth = 0;
          }
          rows.push([{ ...item, isFullRow: true }]);
        } else if (currentWidth + itemWidth > this.maxWidth) {
          rows.push(currentRow);
          currentRow = [item];
          currentWidth = itemWidth;
        } else {
          currentRow.push(item);
          currentWidth += itemWidth;
        }
      });

      if (currentRow.length > 0) {
        rows.push(currentRow);
      }

      return rows;
    },
  },
  watch: {
    calculatedRows: {
      handler(newVal) {
        setTimeout(() => {
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].calculatedRowsWatchData = newVal;
        }, 1000);
      },
      deep: true
    },
    getTextWidthData: {
      handler(newVal) {
        setTimeout(() => {
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].getTextWidthWatchData = newVal;
        }, 1000);
      },
      deep: true
    },
  },
  methods: {
    getTextWidth(text, fontSize, fontFamily) {
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      context.font = `${fontSize}px ${fontFamily}`;
      this.getTextWidthData = context.measureText(text).width;
      return context.measureText(text).width;
    },

    getTextStyle(item) {
      let selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      let textAlign =
        this.element.propertiesArray[selectedVariationIndex]?.container_style
          ?.textAlign || "center";

      return {
        color: item?.color,
        fontFamily:
          this.element.propertiesArray[
            this.element.properties.selectedVariationIndex
          ].font_family.value,
        ...(item?.isBold && {
          fontWeight: "bold",
        }),
        ...(item?.isItalic && {
          fontStyle: "italic",
        }),
        fontSize: "16px",
        lineHeight: "1.5",
        textAlign: textAlign,
        wordBreak: "break-word",
      };
    },

    getItemPadding(item) {
      let selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      let columnSpace =
        this.element.propertiesArray[selectedVariationIndex]?.columnSpace || 0;
      return {
        padding: `${columnSpace}px`,
      };
    },

    getItemStyle(item) {
      let selectedVariationIndex =
        this.element.properties.selectedVariationIndex;
      let borderStyles =
        this.element.propertiesArray[selectedVariationIndex]?.borderStyles ||
        {};
      let bgColor =
        {
          backgroundColor: this.element.propertiesArray[selectedVariationIndex]?.style.backgroundColor
        } ||
        {};
      let hasBorder =
        borderStyles.borderTopWidth != "0px" ||
        borderStyles.borderRightWidth != "0px" ||
        borderStyles.borderBottomWidth != "0px" ||
        borderStyles.borderLeftWidth != "0px";


      return hasBorder ? { ...borderStyles,...bgColor } : {...bgColor};
    },
    ensureHttps(url) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        return `https://${url}`;
      }
      return url;
    },
  },
};
</script>

<style scoped></style>
