<template>
    <div v-if="templateData.header.status" v-html="templateData.header.content" class="header-content"></div>
</template>

<script>

export default {
    name: "headerRender",
    components: {

    },
    props: {
        templateData: Object
    },
    methods: {},
};
</script>

<style scoped>

</style>
