<template>
  <table height="0px" border="0" cellpadding="0" cellspacing="0"
    :align="element.propertiesArray[element.properties.selectedVariationIndex].alignment"
    :width="element.propertiesArray[element.properties.selectedVariationIndex].style.width" :style="{
      borderTopWidth: element.propertiesArray[element.properties.selectedVariationIndex].borderStyles.borderTopWidth,
      borderTopStyle: element.propertiesArray[element.properties.selectedVariationIndex].borderStyles.borderStyle,
      borderTopColor: element.propertiesArray[element.properties.selectedVariationIndex].borderStyles.borderColor,
    }" style="
      border-collapse: collapse;
      table-layout: fixed;
      border-spacing: 0;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
      vertical-align: top;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%">
    <tbody>
      <tr style="vertical-align: top">
        <td style="
          word-break: break-word;
          border-collapse: collapse !important;
          vertical-align: top;
          font-size: 0px;
          line-height: 0px;
          mso-line-height-rule: exactly;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%">
          <span>&#160;</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { throttleFunction } from "@/utils/helper";

export default {
  name: "lineViewRender",
  inject: ["draggable_editor_data"],
  components: {},
  props: {
    element: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    mainContainerWidth: String,
    responsive: Boolean,
    emailMailTo: "",
  },
  data() {
    return {};
  },

  watch: {
    $props: {
      handler() {
        this.lineWidthModifyHandler();
      },
      deep: true,
      immediate: false,
    },

    responsive() {
      this.lineWidthModifyHandler();
    },

    mainContainerWidth() {
      this.lineWidthModifyHandler();
    },
  },
  created() {
    this.lineWidthModifyHandler = throttleFunction(this.modify_line_width, 0);
    window.addEventListener(
      "resize",
      throttleFunction(this.widthChangeHandler, 0)
    );
  },
  mounted() {
    this.lineWidthModifyHandler();
  },

  unmounted() {
    window.removeEventListener("resize", this.widthChangeHandler);
  },

  methods: {
    widthChangeHandler() {
      this.lineWidthModifyHandler();
    },
    modify_line_width() {
      if (
        this.columnType &&
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.element.properties.selectedVariationIndex]
          .line_width
      ) {
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.element.properties.selectedVariationIndex
        ].line_width.width = this.$refs.lineWidth?.clientWidth + "px";
      }
    },
  },
};
</script>
