<template>
  <div class="widget-htmlEditor" v-if="element.name === 'widget_table_editor'">
    <div v-if="element.name === 'widget_table_editor' &&
      element.propertiesArray[element.properties.selectedVariationIndex]
        .tableProperties
      " class="dynamic-table">
      <table :style="{
        ...element.propertiesArray[element.properties.selectedVariationIndex]
          .style,
        ...((element.propertiesArray[element.properties.selectedVariationIndex]
          ?.borderStyles?.borderTopWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderRightWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderBottomWidth != '0px' ||
          element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles?.borderLeftWidth != '0px') && {
          ...element.propertiesArray[element.properties.selectedVariationIndex]
            ?.borderStyles,
        }),
      }" v-if="element.name === 'widget_table_editor' &&
  !element.propertiesArray[element.properties.selectedVariationIndex]
    .tableProperties.isDynamic
  ">
        <thead v-if="element.propertiesArray[element.properties.selectedVariationIndex]
            .tableProperties.staticTable.isHeaderChecked
          ">
          <tr v-for="(headerRow, rowIndex) in element.propertiesArray[
            element.properties.selectedVariationIndex
          ].tableProperties.staticTable.headers" :key="rowIndex">
            <th :style="{
              backgroundColor: element.propertiesArray[
                element.properties.selectedVariationIndex
              ].tableProperties.headerStyle.isVisible
                ? element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.headerStyle.backgroundColor
                : '#FFFFFF',
              border:
                '1px ' +
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.tableBorderSelectedStyle.borderStyle +
                ' ' +
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.tableBorderSelectedStyle.borderColor,
              paddingTop:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingTop,
              paddingRight:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingRight,
              paddingBottom:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingBottom,
              paddingLeft:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingLeft,
            }" v-for="(header, headerIndex) in headerRow" :key="headerIndex">
              <!-- {{ header.content }} -->
              <template v-if="header.type === 'Image' && header.content">
                <img :src="header.content" alt="Uploaded Image" />
              </template>
              <template v-else>
                <div class="" style="color: #000000; font-weight: 400;" v-html="header.content"></div>
              </template>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in element.propertiesArray[
            element.properties.selectedVariationIndex
          ].tableProperties.staticTable.tableData" :key="rowIndex">
            <td :style="{
              backgroundColor: element.propertiesArray[
                element.properties.selectedVariationIndex
              ].tableProperties.fullTableStyle.isVisible
                ? element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.fullTableStyle.backgroundColor
                : '#FFFFFF',
              border:
                '1px ' +
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.tableBorderSelectedStyle.borderStyle +
                ' ' +
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.tableBorderSelectedStyle.borderColor,

              paddingTop:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingTop,
              paddingRight:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingRight,
              paddingBottom:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingBottom,
              paddingLeft:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingLeft,
            }" v-for="(cell, cellIndex) in row" :key="cellIndex">
              <template v-if="cell.type === 'Image' && cell.content">
                <table>
                  <tr>
                    <td :align="cell?.img_properties?.alignment">
                      <img :src="cell.content" :width="cell.properties.width" :alt="cell.properties.alterText" />
                    </td>
                  </tr>
                </table>
              </template>
              <template v-else>
                <div class="" style="color: #000000; font-weight: 400;" v-html="cell.content"></div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table v-if="element.name === 'widget_table_editor' &&
        element.propertiesArray[element.properties.selectedVariationIndex]
          .tableProperties.isDynamic
        ">
        <thead v-if="element.propertiesArray[element.properties.selectedVariationIndex]
            .tableProperties.dynamicTable.isHeaderChecked
          ">
          <tr v-for="(headerRow, rowIndex) in element.propertiesArray[
            element.properties.selectedVariationIndex
          ].tableProperties.dynamicTable.headers" :key="rowIndex">
            <th :style="{
              backgroundColor: element.propertiesArray[
                element.properties.selectedVariationIndex
              ].tableProperties.headerStyle.isVisible
                ? element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.headerStyle.backgroundColor
                : '#FFFFFF',
              border:
                '1px ' +
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.tableBorderSelectedStyle.borderStyle +
                ' ' +
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.tableBorderSelectedStyle.borderColor,

              paddingTop:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingTop,
              paddingRight:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingRight,
              paddingBottom:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingBottom,
              paddingLeft:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingLeft,
            }" v-for="(header, headerIndex) in headerRow" :key="headerIndex">
              <!-- {{ header.content }} -->
              <template v-if="header.type === 'Image' && header.content">
                <img :src="header.content" alt="Uploaded Image" />
              </template>
              <template v-else>
                <div class="" style="color: #000000; font-weight: 400;" v-html="header.content"></div>
              </template>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in element.propertiesArray[
            element.properties.selectedVariationIndex
          ].tableProperties.dynamicTable.tableData" :key="rowIndex">
            <td :style="{
              backgroundColor: element.propertiesArray[
                element.properties.selectedVariationIndex
              ].tableProperties.fullTableStyle.isVisible
                ? element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.fullTableStyle.backgroundColor
                : '#FFFFFF',
              border:
                '1px ' +
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.tableBorderSelectedStyle.borderStyle +
                ' ' +
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.tableBorderSelectedStyle.borderColor,

              paddingTop:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingTop,
              paddingRight:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingRight,
              paddingBottom:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingBottom,
              paddingLeft:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingLeft,
            }" v-for="(cell, cellIndex) in row" :key="cellIndex">
              <template v-if="cell.type === 'Image' && cell.content">
                <table>
                  <tr>
                    <td :align="cell?.img_properties?.alignment">
                      <img :src="cell.content" :width="cell.properties.width" :alt="cell.properties.alterText" />
                    </td>
                  </tr>
                </table>
              </template>
              <template v-else>
                <div class="" style="color: #000000; font-weight: 400;" v-html="cell.content"></div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <table v-if="element.name === 'widget_table_editor' &&
        element.propertiesArray[element.properties.selectedVariationIndex]
          .tableProperties.dynamicTable.isStaticRowChecked &&
        element.propertiesArray[element.properties.selectedVariationIndex]
          .tableProperties.isDynamic
        ">
        <tbody>
          <tr v-for="(row, rowIndex) in element.propertiesArray[
            element.properties.selectedVariationIndex
          ].tableProperties.dynamicTable.staticRowData" :key="rowIndex">
            <td :style="{
              backgroundColor: element.propertiesArray[
                element.properties.selectedVariationIndex
              ].tableProperties.staticRowStyle.isVisible
                ? element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.staticRowStyle.backgroundColor
                : '#FFFFFF',
              border:
                '1px ' +
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.tableBorderSelectedStyle.borderStyle +
                ' ' +
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.tableBorderSelectedStyle.borderColor,

              paddingTop:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingTop,
              paddingRight:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingRight,
              paddingBottom:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingBottom,
              paddingLeft:
                element.propertiesArray[
                  element.properties.selectedVariationIndex
                ].tableProperties.table_cell_padding_style.paddingLeft,
            }" v-for="(cell, cellIndex) in row" :key="cellIndex">
              <template v-if="cell.type === 'Image' && cell.content">{{}}
                <table>
                  <tr>
                    <td :align="cell?.img_properties?.alignment">
                      <img :src="cell.content" :width="cell.properties.width" :alt="cell.properties.alterText" />
                    </td>
                  </tr>
                </table>
              </template>
              <template v-else>
                <div class="" style="color: #000000; font-weight: 400;" v-html="cell.content"></div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "tableViewRender",

  components: {},
  props: {
    element: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
