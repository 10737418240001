<template>
  <div v-if="templateData.footer.status" v-html="templateData.footer.content" class="footer-content widget-text" style="
      display: block;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 0;
      /* text-align: center; */
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
    "></div>
  <div class="" style="
      text-align: center;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
    ">
    <div class="" style="
        display: block;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 15px;
        color: #000000;
      "></div>

    <!-- <div v-if="!templateData.footer.status" class="text-center">
      <span class="font-14">@Model.unsubscribe</span>
    </div> -->
    <!-- {{ templateData.footer.optInInfo.remindPeopleHowSignedUp }} -->
    <div v-if="
  (templateData.footer.optInInfo.optInCompanyName ||
    templateData.footer.optInInfo.optInCompanyAddress ||
    templateData.footer.optInInfo.optInCompanyCity ||
    templateData.footer.optInInfo.optInCompanyZip ||
    templateData.footer.optInInfo.optInCompanyCountry !=
    'Select Country') &&
  templateData.footer.status
    ">
      <div class="" style="
          display: block;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 0;
          color: #000000;
        ">
        <!-- Our mailing address is: -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerRender",
  components: {},
  props: {
    templateData: Object,
  },
  methods: {},
};
</script>

<style scoped>

</style>
